import React, { useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

import FormGroup from "./utils/FormGroup";
import MIME_TYPE from "../config/MIME_TYPE";
import { Deactivate } from "../utils/ActivateAndDeactivate";
import Notes from "../services/notes";
import { backendApiUrl, formatBytes } from "../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";

import "./dropzone.css";

const FilePicker = (props) => {
  const [showNote, setShowNote] = useState(false);
  const [showFiles, setFiles] = useState(false);
  let MI = { ...props.module };

  const [doc, setDoc] = useState(
    MI.uploadedDocs && MI.uploadedDocs[props.element.id]
      ? MI.uploadedDocs[props.element.id]
      : {}
  );

  let mime_types = "";
  if (props.element.file_type) {
    props.element.file_type.forEach((f) => {
      mime_types += !!mime_types ? ", " + f.value : f.value;
    });
  } else {
    MIME_TYPE.forEach((f) => {
      mime_types += !!mime_types ? ", " + f.value : f.value;
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: mime_types,
    multiple:
      props.element.isMultiple || props.element.isMultiple === undefined
        ? true
        : false,
    onDrop: (acceptedFiles) => {
      let d = {
        docType: props.element.id,
        files: acceptedFiles,
        status: "Awaiting Review",
        module: props.module.from,
        owner: props.module.MDID,
      };

      if (MI.files) {
        if (MI.files[props.element.id]) {
          MI.files[props.element.id].files = [
            ...MI.files[props.element.id].files,
            ...acceptedFiles,
          ];
        } else {
          MI.files[props.element.id] = d;
        }
      } else {
        MI.files = {
          [props.element.id]: d,
        };
      }

      props.events.setModuleInfo(MI);
    },
  });

  const deleteNewFile = (elm, file) => {
    let files = [];
    MI.files[elm].files.forEach((f) => {
      if (f.name !== file.name) {
        files = [...files, f];
      }
    });
    MI.files[elm].files = files;
    props.events.setModuleInfo(MI);
  };

  let new_files = "";
  if (
    MI.files &&
    MI.files[props.element.id] &&
    MI.files[props.element.id].files
  ) {
    new_files = MI.files[props.element.id].files.map((file) => (
      <li key={file.path}>
        <span className="mr-2">
          {file.path} - {formatBytes(file.size, 2)}&nbsp;
          <i
            className="fa fa-trash fa-lg text-danger"
            title="Deactivate"
            onClick={() => deleteNewFile(props.element.id, file)}
          />
        </span>
      </li>
    ));
  }

  const statusOnChangeHandler = (e) => {
    if (MI.files && MI.files[props.element.id]) {
      MI.files[props.element.id] = {
        ...MI.files[props.element.id],
        status: e.target.value,
      };
    } else {
      let d = {
        docType: props.element.id,
        status: e.target.value,
        module: props.module.from,
        owner: props.module.MDID,
      };

      if (MI.files) {
        MI.files[props.element.id] = d;
      } else {
        MI.files = {
          [props.element.id]: d,
        };
      }
    }
    props.events.setModuleInfo(MI);
  };

  const previewOnClickHandler = (id) => {
    axios
      .get(backendApiUrl("auth/file/view/" + id), {
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        let extension =
          res.headers["content-type"] === "text/csv; charset=UTF-8"
            ? "csv"
            : "";
        MIME_TYPE.forEach((m) => {
          if (res.headers["content-type"] === m.value) extension = m.label;
        });
        //Build a URL from the file
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        if (["docx", "doc", "csv"].includes(extension))
          link.setAttribute("download", "file." + extension); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDeleteHandler = (msg) => {
    if (!!msg._id) {
      let files = [];
      doc.files.forEach((f) => {
        if (msg._id !== f._id) files = [...files, f];
      });
      let d = { ...doc };
      d.files = files;
      setDoc(d);
      props.events.setMsg(msg);
    }
  };

  let fileExists = 0;
  const old_files = doc.files
    ? doc.files.map((f, z) => {
        let obj = f.name.split(".");
        let filename =
          props.element.title + "_" + (z + 1) + "." + obj[obj.length - 1];
        if (MI.dynamicFieldData && MI.dynamicFieldData.auto_increment) {
          filename = MI.dynamicFieldData.auto_increment + "_" + filename;
        }

        if (f.active || f.active === undefined) fileExists++;
        return (
          (f.active || f.active === undefined) && (
            <li key={f._id}>
              <span
                className="mr-2"
                onClick={() => previewOnClickHandler(f._id)}
              >
                {filename} - {formatBytes(f.size, 2)}&nbsp;
                <i
                  className="fa fa-eye fa-lg text-success"
                  aria-hidden="true"
                />
              </span>
              {allowToEdit && (
                <Deactivate
                  url={backendApiUrl("docs/file/" + f._id)}
                  id={f._id}
                  actions={{
                    msg: onDeleteHandler,
                  }}
                  title={f.name}
                />
              )}
            </li>
          )
        );
      })
    : "";

  // Permissions
  let allowToEdit = false;
  let permissions = {};
  if ((props.user && props.user.role.admin) || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.docs) {
    permissions = MI.permissions.docs;
  }

  if ((permissions.add && doc._id === undefined) || permissions.update) {
    allowToEdit = true;
  }

  // March 17 2023 - As per sam and rajesh request
  if (props.user && props.user.type === "Prospects") {
    allowToEdit = false;
  }

  let status =
    MI.files && MI.files[props.element.id] && MI.files[props.element.id]
      ? MI.files[props.element.id].status
      : doc.status;

  let bgcolor = "";
  if (status === "Not Uploaded" || status === undefined) bgcolor = "bg-danger";
  if (status === "Awaiting Review") bgcolor = "bg-info";
  if (status === "Awaiting Reupload") bgcolor = "bg-warning";
  if (status === "Approved") bgcolor = "bg-success";

  return (
    <FormGroup element={props.element} module={props.module}>
      <div className="row">
        <div className="col-md-12">
          <div
            {...getRootProps({ className: "dropzone" + props.element.class })}
          >
            <input
              {...getInputProps()}
              name={props.element.id}
              id={props.element.id}
            />
            <h6 className="text-primary">Drag & Drop files here</h6>
          </div>
        </div>

        {props.user !== undefined && (
          <div className="col-md-8" style={{ marginTop: "10px" }}>
            {allowToEdit ? (
              <select
                className={bgcolor}
                value={status}
                onChange={statusOnChangeHandler}
                style={{ color: "#fff", width: "100%" }}
              >
                {[
                  "Not Uploaded",
                  "Awaiting Review",
                  "Awaiting Reupload",
                  "Approved",
                ].map((t, i) => (
                  <option value={t} key={i}>
                    {t}
                  </option>
                ))}
              </select>
            ) : (
              <span className={bgcolor}>{status}</span>
            )}
          </div>
        )}
        <div className="col-md-4 mt-2" style={{ marginTop: "10px" }}>
          {!!doc._id && doc.notes && doc.notes.length > 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <table>
                    <thead>
                      <th>Notes</th>
                      <th>Type</th>
                    </thead>
                    {doc.notes && doc.notes.length > 0
                      ? doc.notes.map((n, i) => (
                          <tbody key={i}>
                            <tr key={i}>
                              <td>{n.content}</td>
                              <td>{n.type}</td>
                            </tr>
                          </tbody>
                        ))
                      : ""}
                  </table>
                </Tooltip>
              }
            >
              <i
                className="fa fa-comments mr-2 fa-lg green-color"
                style={{ color: "Green" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : !!doc._id && doc.notes && doc.notes.length === 0 ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>No Notes available...</Tooltip>}
            >
              <i
                className="fa fa-comments mr-2 fa-lg"
                style={{ color: "red" }}
                onClick={() => setShowNote(true)}
              />
            </OverlayTrigger>
          ) : (
            ""
          )}
          {!!old_files && fileExists > 0 && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Click here to View Existing Files..</Tooltip>}
            >
              <i
                className="fa fa-file mr-2 fa-lg"
                style={{ color: "green" }}
                onClick={() => setFiles(true)}
                placeholder="Click here to View Existing Files.."
              />
            </OverlayTrigger>
          )}
        </div>
        <div className="col-md-12 mt-2">
          {props.module.form.guidelines &&
            props.module.form.guidelines[props.element.id] && (
              <div className="has-error">
                {props.module.form.guidelines[props.element.id]}
              </div>
            )}

          {!!new_files && (
            <aside className="mt-2" name={props.element.id}>
              <h6>New Files</h6>
              <ul>{new_files}</ul>
            </aside>
          )}
        </div>
      </div>
      {/*<table width="100%">
        <tbody>
          <tr>
            <td width="60%">
              <div
                {...getRootProps({
                  className: "dropzone" + props.element.class,
                })}
              >
                <input
                  {...getInputProps()}
                  name={props.element.id}
                  id={props.element.id}
                />
                <h6 className="text-primary">Drag & Drop files here</h6>
              </div>
            </td>
            <td width="30%">
              {props.user !== undefined && (
                <div className="mb-1">
                  {allowToEdit ? (
                    <select
                      className={bgcolor + " form-control"}
                      value={status}
                      onChange={statusOnChangeHandler}
                      style={{ color: "#fff" }}
                    >
                      {[
                        "Not Uploaded",
                        "Awaiting Review",
                        "Awaiting Reupload",
                        "Approved",
                      ].map((t, i) => (
                        <option value={t} key={i}>
                          {t}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span className={bgcolor}>{status}</span>
                  )}
                </div>
              )}
            </td>
            {!!doc._id && (
              <td width="20%">
                <button
                  className="btn btn-info"
                  onClick={() => setShowNote(true)}
                >
                  View/Add Notes
                </button>
              </td>
            )}
          </tr>
          <tr>
            <td colSpan={3}>
              <div style={{ marginTop: "10px" }}>
                {props.module.form.guidelines &&
                  props.module.form.guidelines[props.element.id] && (
                    <div className="has-error">
                      {props.module.form.guidelines[props.element.id]}
                    </div>
                  )}

                {!!new_files && (
                  <aside className="mt-2" name={props.element.id}>
                    <h6>New Files</h6>
                    <ul>{new_files}</ul>
                  </aside>
                )}

                {!!old_files && fileExists > 0 && (
                  <Accordion>
                    <Card>
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey="ex-docs"
                        style={{ padding: "10px" }}
                      >
                        Existing Files &nbsp;
                        <i
                          className="fa fa-solid fa-chevron-down"
                          style={{ float: "right" }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="ex-docs">
                        <Card.Body>
                          <ul>{old_files}</ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      */}

      {showNote && (
        <Modal show={true} size="xl" backdrop="static" keyboard={false}>
          <Modal.Body>
            <Notes
              module="docs"
              permissions={{ notes: { add: true, update: true } }}
              owner={doc._id}
              MI={MI}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn primary"
              onClick={() => setShowNote(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {showFiles && (
        <Modal show={true} size="lg" backdrop="static" keyboard={false}>
          <Modal.Body>
            <div className="mt-3">
              <Portlet>
                <PortletHeader title="Existing Files" />
                <PortletBody>
                  {!!old_files && fileExists > 0 && old_files}
                </PortletBody>
              </Portlet>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn primary" onClick={() => setFiles(false)}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </FormGroup>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(FilePicker);

import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import { LayoutSplashScreen } from "../_metronic";
import { shallowEqual, useSelector } from "react-redux";

import Opportunities from "./opportunities";
import UpdateOpportunity from "./opportunities/Edit";

const Module = React.lazy(() => import("./module"));
const CreateModuleData = React.lazy(() => import("./module/Create"));
const EditModuleData = React.lazy(() => import("./module/Edit"));

const Products = React.lazy(() => import("./products"));
const NewProduct = React.lazy(() => import("./products/New"));
const UpdateProduct = React.lazy(() => import("./products/Update"));

const ModuleBuilder = React.lazy(() => import("./builders/modules/Builder"));
const Modules = React.lazy(() => import("./builders/modules"));
const NewModule = React.lazy(() => import("./builders/modules/New"));
const UpdateModule = React.lazy(() => import("./builders/modules/Update"));

const OpportunityBuilder = React.lazy(() =>
  import("./builders/opportunity/Builder")
);
const BuilderOpportunities = React.lazy(() => import("./builders/opportunity"));
const NewBuilderOpportunity = React.lazy(() =>
  import("./builders/opportunity/New")
);
const UpdateBuilderOpportunity = React.lazy(() =>
  import("./builders/opportunity/Update")
);
const OpportunityOpenApi = React.lazy(() =>
  import("./builders/opportunity/openapi")
);

const WebformBuilder = React.lazy(() => import("./builders/webforms/Builder"));
const Webforms = React.lazy(() => import("./builders/webforms"));
const NewWebform = React.lazy(() => import("./builders/webforms/New"));
const UpdateWebform = React.lazy(() => import("./builders/webforms/Update"));
const OpenApiWebform = React.lazy(() => import("./builders/webforms/openapi"));

const LibraryBuilder = React.lazy(() => import("./builders/libraries/Builder"));
const Libraries = React.lazy(() => import("./builders/libraries"));
const NewLibrary = React.lazy(() => import("./builders/libraries/New"));
const UpdateLibrary = React.lazy(() => import("./builders/libraries/Update"));

const Companies = React.lazy(() => import("./companies"));
const NewCompany = React.lazy(() => import("./companies/New"));
const CompanyProfile = React.lazy(() => import("./companies/Profile"));
const CompanyUpdate = React.lazy(() => import("./companies/Update"));

const Leads = React.lazy(() => import("./leads"));
const NewLead = React.lazy(() => import("./leads/Create"));
const UpdateLead = React.lazy(() => import("./leads/Edit"));

const ContractBuilder = React.lazy(() => import("./builders/contract_builder"));
const EditContract = React.lazy(() =>
  import("./builders/contract_builder/edit")
);
const GenerateContract = React.lazy(() =>
  import("./builders/contract_builder/generateContract")
);

const EmailTemplates = React.lazy(() => import("./builders/email_templates"));
const CreateEmailTemplate = React.lazy(() =>
  import("./builders/email_templates/Create")
);
const EditEmailTemplate = React.lazy(() =>
  import("./builders/email_templates/Edit")
);
const BuilderEmailTemplate = React.lazy(() =>
  import("./builders/email_templates/Builder")
);
/*const TinyBuilder = React.lazy(() =>
  import("./builders/email_templates/TinyBuilder")
);
*/

const Roles = React.lazy(() => import("./security/roles"));
const CreateWorkforceRole = React.lazy(() =>
  import("./security/roles/workforce/create")
);
const EditWorkforceRole = React.lazy(() =>
  import("./security/roles/workforce/edit")
);
const CreateContactRole = React.lazy(() =>
  import("./security/roles/contact/create")
);
const EditContactRole = React.lazy(() =>
  import("./security/roles/contact/edit")
);

const Groups = React.lazy(() => import("./groups"));
const CreateGroup = React.lazy(() => import("./groups/Create"));
const EditGroup = React.lazy(() => import("./groups/Edit"));
const DocsView = React.lazy(() => import("./docs/view"));

const ContractTemplates = React.lazy(() => import("./contracts/template"));
const CreateContract = React.lazy(() => import("./contracts/template/Create"));
const EditContractN = React.lazy(() => import("./contracts/template/Edit"));
const BuilderContract = React.lazy(() =>
  import("./contracts/template/Builder")
);

const BuilderPreviewContract = React.lazy(() =>
  import("./contracts/template/Preview")
);
const Contracts = React.lazy(() => import("./contracts"));
const Reports = React.lazy(() => import("./reports"));
const CreateReport = React.lazy(() => import("./reports/Create"));
const EditReport = React.lazy(() => import("./reports/Edit"));
const PublicDocs = React.lazy(() => import("./public/docs"));

const BTasks = React.lazy(() => import("./builders/tasks"));
const BTaskCreate = React.lazy(() => import("./builders/tasks/Create"));
const BTaskEdit = React.lazy(() => import("./builders/tasks/Edit"));

const Integration = React.lazy(() => import("./services/integrations"));

const Import = React.lazy(() => import("./services/imports"));
const CreateImport = React.lazy(() => import("./services/imports/Create"));
const EditImport = React.lazy(() => import("./services/imports/Edit"));

const EmailTriggers = React.lazy(() => import("./builders/email_triggers"));
const CreateEmailTrigger = React.lazy(() =>
  import("./builders/email_triggers/Create")
);
const EditEmailTrigger = React.lazy(() =>
  import("./builders/email_triggers/Edit")
);
const BuilderEmailTrigger = React.lazy(() =>
  import("./builders/email_triggers/Builder")
);
const Payments = React.lazy(() => import("./payments"));
const ContractView = React.lazy(() =>
  import("./builders/contract_builder/view")
);
const UnsubscribeEmail = React.lazy(() => import("./emails/unsubscribe"));

const Campaigns = React.lazy(() => import("./campaigns"));
const CreateCampaign = React.lazy(() => import("./campaigns/Create"));
const Emails = React.lazy(() => import("./campaigns/Emails"));
const ViewStandardDoc = React.lazy(() => import("./docs/standard_docs/View"));
const MapFields = React.lazy(() => import("./companies/MapFields"));
const EmailList = React.lazy(() => import("./email_list"));
const CreateEmailList = React.lazy(() => import("./email_list/Create"));
const EmailListEdit = React.lazy(() => import("./email_list/Edit"));

export default function HomePage() {
  const { menuConfig, user } = useSelector(
    ({ builder: { menuConfig }, auth }) => ({
      menuConfig,
      user: auth.user,
    }),
    shallowEqual
  );
  let modules = [...menuConfig.header.items];
  let pages = [];
  modules.forEach((m) => {
    if (m.show_menu !== false) pages = [...pages, m.page];
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect
          exact
          from="/"
          to={
            user &&
            user.role &&
            user.role.company === "62154bff005ff4472cf5ab74"
              ? "/campaigns"
              : "/" + pages[0]
          }
        />
        <Route path="/dashboard" component={Dashboard} />
        {modules.map(
          (module, i) =>
            module.createdAt !== undefined &&
            module.page !== "dashboard" && (
              <Route path={"/" + module.page} key={i} exact>
                <Module />
              </Route>
            )
        )}

        {modules.map(
          (module, i) =>
            module.createdAt !== undefined &&
            module.page !== "dashboard" && (
              <Route path={"/" + module.page + "/create"} key={i} exact>
                <CreateModuleData />
              </Route>
            )
        )}

        {modules.map(
          (module, i) =>
            module.createdAt !== undefined &&
            module.page !== "dashboard" && (
              <Route path={"/" + module.page + "/edit/:MDID"} key={i} exact>
                <EditModuleData />
              </Route>
            )
        )}

        {menuConfig.loggedInUserType === "workforce" && (
          <Route path={"/workforces/edit/:MDID"} exact>
            <EditModuleData />
          </Route>
        )}

        {menuConfig.loggedInUserType === "prospect" && (
          <Route path={"/prospects/edit/:MDID"} exact>
            <EditModuleData />
          </Route>
        )}

        {menuConfig.loggedInUserType === "contact" && (
          <Route path={"/contacts/edit/:MDID"} exact>
            <EditModuleData />
          </Route>
        )}

        <Route path="/companies" exact>
          <Companies />
        </Route>
        <Route path="/doc/view/:doc_id" exact>
          <DocsView />
        </Route>
        <Route path="/companies/new" exact>
          <NewCompany />
        </Route>
        <Route path="/companies/:company_id" exact>
          {menuConfig.loggedInUserType === "Super" ? (
            <CompanyProfile />
          ) : (
            <CompanyUpdate />
          )}
        </Route>

        <Route path="/opportunities" exact>
          <Opportunities />
        </Route>

        <Route path="/opportunities/edit/:opportunity_id" exact>
          <UpdateOpportunity />
        </Route>

        <Route path="/builders/modules" exact>
          <Modules />
        </Route>
        <Route path="/builders/modules/create" exact>
          <NewModule />
        </Route>
        <Route path="/builders/modules/edit/:module_id" exact>
          <UpdateModule />
        </Route>
        <Route path="/builders/modules/form" exact>
          <ModuleBuilder />
        </Route>

        <Route path="/builders/opportunity" exact>
          <BuilderOpportunities />
        </Route>
        <Route path="/builders/opportunity/create" exact>
          <NewBuilderOpportunity />
        </Route>
        <Route path="/builders/opportunity/edit/:opportunity_id" exact>
          <UpdateBuilderOpportunity />
        </Route>
        <Route path="/builders/opportunity/form" exact>
          <OpportunityBuilder />
        </Route>
        <Route path="/builders/opportunity/api/:opportunity_id" exact>
          <OpportunityOpenApi />
        </Route>

        <Route path="/builders/tasks" exact>
          <BTasks />
        </Route>
        <Route path="/builders/task/create" exact>
          <BTaskCreate />
        </Route>
        <Route path="/builders/task/edit/:task_id" exact>
          <BTaskEdit />
        </Route>

        <Route path="/builders/webforms" exact>
          <Webforms />
        </Route>
        <Route path="/builders/webforms/create" exact>
          <NewWebform />
        </Route>
        <Route path="/builders/webforms/edit/:webform_id" exact>
          <UpdateWebform />
        </Route>
        <Route path="/builders/webforms/openapi/:webform_id" exact>
          <OpenApiWebform />
        </Route>

        <Route path="/builders/webforms/form" exact>
          <WebformBuilder />
        </Route>

        <Route path="/builders/libraries" exact>
          <Libraries />
        </Route>
        <Route path="/builders/libraries/create" exact>
          <NewLibrary />
        </Route>
        <Route path="/builders/libraries/edit/:library_id" exact>
          <UpdateLibrary />
        </Route>
        <Route path="/builders/libraries/form" exact>
          <LibraryBuilder />
        </Route>

        <Route path="/email_templates" exact>
          <EmailTemplates />
        </Route>
        <Route path="/email_templates/create" exact>
          <CreateEmailTemplate />
        </Route>
        <Route path="/email_templates/edit/:template_id" exact>
          <EditEmailTemplate />
        </Route>
        {/*
        <Route path="/email_templates/builder/:template_id" exact>
          {user &&
          user.role &&
          user.role.company === "62154bff005ff4472cf5ab74" ? (
            <TinyBuilder user={user} />
          ) : (
            <BuilderEmailTemplate />
          )}
        </Route>
          */}
        <Route path="/email_templates/builder/:template_id" exact>
          <BuilderEmailTemplate />
        </Route>

        <Route path="/builders/contracts" exact>
          <ContractTemplates />
        </Route>
        <Route path="/builders/contracts/create" exact>
          <CreateContract />
        </Route>
        <Route path="/builders/contracts/edit/:contract_id" exact>
          <EditContractN />
        </Route>
        <Route path="/builders/contracts/builder/:contract_id" exact>
          <BuilderContract />
        </Route>
        <Route path="/builders/contracts/view/:contract_id" exact>
          <ContractView />
        </Route>
        <Route
          path="/builders/contracts/builder/preview/:contract_id/:formId/:ownerId/:url"
          exact
        >
          <BuilderPreviewContract />
        </Route>

        <Route path="/contracts" exact>
          <Contracts />
        </Route>
        <Route path="/builders/contract_builder" exact>
          <ContractBuilder />
        </Route>

        <Route path="/builders/contract_builder/edit/:contract_id" exact>
          <EditContract />
        </Route>

        <Route
          path="/builders/contract_builder/generateContract/:genarationtype/:module/:MDID/:contract_id"
          exact
        >
          <GenerateContract />
        </Route>

        <Route path="/leads" exact>
          <Leads />
        </Route>
        <Route path="/forms/:form_id" exact>
          <NewLead />
        </Route>
        <Route path="/leads/:lead_id" exact>
          <UpdateLead />
        </Route>

        <Route path="/products" exact>
          <Products />
        </Route>
        <Route path="/products/create" exact>
          <NewProduct />
        </Route>
        <Route path="/products/:product_id" exact>
          <UpdateProduct />
        </Route>

        <Route path="/reports" exact>
          <Reports />
        </Route>
        <Route path="/reports/create" exact>
          <CreateReport />
        </Route>
        <Route path="/reports/edit/:report_id" exact>
          <EditReport />
        </Route>

        <Route path="/security/roles" exact>
          <Roles />
        </Route>
        <Route path="/security/roles/workforce/create" exact>
          <CreateWorkforceRole />
        </Route>
        <Route path="/security/roles/workforce/edit/:role_id" exact>
          <EditWorkforceRole />
        </Route>
        <Route path="/security/roles/contact/create" exact>
          <CreateContactRole />
        </Route>
        <Route path="/security/roles/contact/edit/:role_id" exact>
          <EditContactRole />
        </Route>

        <Route path="/groups" exact>
          <Groups />
        </Route>
        <Route path="/groups/create" exact>
          <CreateGroup />
        </Route>
        <Route path="/groups/edit/:group_id" exact>
          <EditGroup />
        </Route>

        <Route path="/public/docs/:form_id/:owner_id" exact>
          <PublicDocs />
        </Route>

        <Route path="/integrations" exact>
          <Integration />
        </Route>
        <Route path="/imports" exact>
          <Import />
        </Route>
        <Route path="/payments" exact>
          <Payments />
        </Route>
        <Route path="/imports/create" exact>
          <CreateImport />
        </Route>
        <Route path="/imports/edit/:import_id" exact>
          <EditImport />
        </Route>

        <Route path="/builders/email_triggers" exact>
          <EmailTriggers />
        </Route>
        <Route path="/builders/email_triggers/create" exact>
          <CreateEmailTrigger />
        </Route>
        <Route path="/builders/email_triggers/edit/:template_id" exact>
          <EditEmailTrigger />
        </Route>
        <Route path="/builders/email_triggers/builder/:template_id" exact>
          <BuilderEmailTrigger />
        </Route>
        <Route path="/email/unsubscribe" exact>
          <UnsubscribeEmail />
        </Route>

        <Route path="/campaigns" exact>
          <Campaigns />
        </Route>
        <Route path="/campaigns/create" exact>
          <CreateCampaign />
        </Route>
        <Route path="/campaigns/emails/:campaign_id" exact>
          <Emails />
        </Route>

        <Route path="/email_list" exact>
          <EmailList />
        </Route>
        <Route path="/email_list/create" exact>
          <CreateEmailList />
        </Route>
        <Route path="/email_list/edit/:emailListId" exact>
          <EmailListEdit />
        </Route>

        <Route path="/standard_docs/map/:form/:sddoc" exact>
          <MapFields />
        </Route>
        <Route path="/standard_docs/view/:doc/:owner" exact>
          <ViewStandardDoc />
        </Route>

        <Redirect to="/error/error-v5" />
      </Switch>
    </Suspense>
  );
}

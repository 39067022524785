import React, { useState, useEffect } from "react";
import axios from "axios";
import { shallowEqual, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { TablePagination } from "@material-ui/core";
import { useParams } from "react-router-dom";
// import Select from "react-select";
import { connect } from "react-redux";
import { Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdAddLink } from "react-icons/md";
import { Link } from "react-router-dom";

import "../../_metronic/_assets/sass/pipeline.scss";
import { backendApiUrl, getDate } from "../utils";
import Message from "../utils/Message";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../partials/content/Portlet";
import TablePaginationActions from "../shared/TablePaginationActions";
import ComposeEmail from "../services/email/Compose";

import ListSettings, {
  SelectAllRow,
  SelectOneRow,
} from "../utils/ListSettings";
import Filter from "../utils/Filter";
import { Activate, Deactivate } from "../utils/ActivateAndDeactivate";
import MassActions from "../utils/MassActions";
import Sort from "../utils/Sort";
import JumbLink from "../utils/JumbLink";
import StatusUpdate from "./StatusUpdate";
import AssignedTeamMembers from "./AssignedTeamMembers";

const List = (props) => {
  const [msg, setMsg] = useState({ show: false });

  const [totalDocumnetCount, setTotalDocumnetCount] = useState(0);

  const [currentRowStart, setCurrentRowStart] = useState(1);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [opportunities, setOpportunities] = useState({});

  const [isLoading, setLoading] = useState(true);

  const [permissions, setPermissions] = useState({});

  const [actionInfo, setActionInfo] = useState({});

  const [openFilter, setOpenFilter] = useState(false);

  const [filters, setFilters] = useState([]);

  const [sort, setSort] = useState({});

  const [Status, setStatus] = useState([]);

  const [state, setState] = useState({});

  const [wusers, setWUsers] = useState([]);

  // Get Value from query string
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [statusTab, setStatusTab] = useState(
    !!params.get("status") ? params.get("status") : "all"
  );

  let { changedRecords } = useSelector(
    ({ builder }) => ({
      changedRecords: builder.changedRecords,
    }),
    shallowEqual
  );

  let products = [];
  state.products &&
    state.products.forEach((p) => {
      products = [...products, { label: p.title, value: p._id }];
    });

  let search_fields = [
    {
      id: "status",
      title: "Status",
      type: "array",
      outside_field: true,
      options: Status,
    },
  ];

  if (state.fields) search_fields = [...search_fields, ...state.fields];

  let MDID = useParams().MDID;

  if (!!props.MDID) MDID = props.MDID;

  const handleChangePage = (event, newPage) => {
    if (
      Math.max(0, Math.ceil(totalDocumnetCount / rowsPerPage) - 1) === newPage
    ) {
      setCurrentRowStart(totalDocumnetCount - rowsPerPage + 1);
    } else if (page < newPage) {
      setCurrentRowStart(currentRowStart + rowsPerPage);
    } else if (newPage === 0) {
      setCurrentRowStart(1);
    } else {
      setCurrentRowStart(currentRowStart - rowsPerPage);
    }
    setPage(newPage);
    setLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const searchClickHandler = (filters) => {
    setFilters(filters);
    setLoading(true);
  };

  const composeEmailClickHandler = (opp, from) => {
    let data = { from, openCompose: true };
    data.owner = opp._id;
    data.recipients =
      opp.prospects?.map((pr) => ({
        label: `${pr.info?.first_name} ${pr.info?.last_name} (${pr.info?.email})`,
        email: pr.info?.email,
        value: opp._id,
        type: "Opportunities",
      })) || [];

    data.url = backendApiUrl("emails/populate_tags");

    setActionInfo(data);
  };

  // const onProductChange = (p) => {
  //   let st = { ...state };
  //   let programs = [];
  //   p.programs &&
  //     p.programs.forEach((p) => {
  //       programs = [...programs, { value: p._id, label: p.title }];
  //     });
  //   st.programs = programs;
  //   st.product = p;
  //   setState(st);
  // };

  // const onProgramChange = (p) => {
  //   let st = { ...state };
  //   st.program = p;
  //   setState(st);
  //   setLoading(true);
  // };

  const saveListSettings = async (columns) => {
    const bindConfig = (configs) => {
      let bindedConfig = [];
      configs.forEach((config, i) => {
        let tempConfig = { ...config };
        if (
          ![
            "container",
            "grid",
            "item",
            "tab",
            "section",
            "file",
            "paragraph",
            "separator",
            "headertext",
          ].includes(config.type)
        ) {
          tempConfig.order = 10000;
          tempConfig.listShowField = false;
          columns.forEach((field) => {
            if (tempConfig.id === field.id) {
              tempConfig.order = parseInt(field.order);
              tempConfig.listShowField = true;
            }
          });
        } else {
          if (Array.isArray(config.childs))
            tempConfig.childs = bindConfig(config.childs);
        }
        bindedConfig = [...bindedConfig, tempConfig];
      });
      return bindedConfig;
    };

    let form = { ...state.form };
    form.content = bindConfig(form.content);
    await axios
      .patch(backendApiUrl("prospects/forms/" + state.form._id), form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(true);
      });
  };

  useEffect(() => {
    if (isLoading) {
      let data = {
        skip: currentRowStart - 1,
        limit: rowsPerPage,
        type: "Opportunity",
        prospect: MDID,
      };
      // filters
      if (filters.length > 0 && openFilter) data.filters = filters;
      if (state.product) data.product = state.product.value;
      if (state.program) data.program = state.program.value;
      if (sort.field) data.sort = sort;
      if (statusTab !== "all") data.status = statusTab;

      axios
        .post(backendApiUrl("opportunities"), data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setOpportunities(res.data.opportunities);
          setTotalDocumnetCount(res.data.count);
          setPermissions(res.data.permissions);
          setStatus(res.data.status);
          setWUsers(res.data.workforces);

          let columns = [];
          let fields = [];
          let fieldsIds = [];
          const getModuleFields = (configs) => {
            configs.forEach((config) => {
              // Section hide based on roles
              let show = true;
              if (config.hide) {
                if (
                  config.hided_roles &&
                  config.hided_roles.includes(props.user.role._id)
                ) {
                  show = false;
                }
              }

              if (show) {
                if (
                  config.type !== "container" &&
                  config.type !== "grid" &&
                  config.type !== "item" &&
                  config.type !== "tab" &&
                  config.type !== "section" &&
                  config.type !== "file"
                ) {
                  // Avoid list duplicate
                  if (!fieldsIds.includes(config.id)) {
                    fields = [...fields, config];
                    fieldsIds = [...fieldsIds, config.id];
                    if (config.listShowField) {
                      columns = [...columns, config];
                    }
                  }
                } else {
                  if (Array.isArray(config.childs) && !config.cloneable)
                    getModuleFields(config.childs);
                }
              }
            });
          };

          let st = { ...state };
          if (res.data.form) {
            getModuleFields(res.data.form.content);
            fields.sort(function(a, b) {
              return a.order - b.order;
            });

            columns.sort(function(a, b) {
              return a.order - b.order;
            });
            st.form = res.data.form;
            st.columns = columns;
            st.fields = fields;
          }

          st.products = res.data.products;
          st.loanPurpose = res.data.loanPurpose;
          st.applicantType = res.data.applicantType;
          st.webformId = res.data.webformId;
          setState(st);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const statusTabOnClick = (key) => {
    setStatusTab(key);
    setLoading(true);
  };

  return (
    <Portlet>
      <PortletHeader
        title={
          <i
            className="fa fa-search fa-lg text-primary"
            onClick={() => setOpenFilter(openFilter ? false : true)}
            aria-hidden="true"
          />
        }
        toolbar={
          <PortletHeaderToolbar>
            <>
              {permissions.add && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => props.setCrud("create")}
                >
                  Create Opportunity
                </button>
              )}
              &nbsp;
              {state.fields && state.fields.length > 0 && (
                <ListSettings
                  title="List Settings"
                  save={saveListSettings}
                  fields={state.fields}
                  menuConfig={props.menuConfig}
                />
              )}
            </>
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        {msg.show && (
          <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
        )}
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            {openFilter && (
              <span>
                <Filter
                  fields={search_fields}
                  submit={searchClickHandler}
                  filters={filters}
                />
                <div className="kt-separator kt-separator--space-sm kt-separator--border" />
              </span>
            )}

            {actionInfo.Ids && actionInfo.Ids.length > 0 && (
              <span>
                <MassActions
                  url={backendApiUrl("opportunities/mass_action")}
                  module="Opportunities"
                  filters={filters}
                  info={actionInfo}
                  actions={{ setMsg, setLoading, setActionInfo }}
                />
                <div className="kt-separator kt-separator--space-sm kt-separator--border" />
              </span>
            )}
            <br />

            <div className="tabs tabs-style-bar mb-3">
              <Tabs
                activeKey={statusTab}
                onSelect={(key) => statusTabOnClick(key)}
              >
                <Tab
                  eventKey="all"
                  title={"All (" + totalDocumnetCount + ")"}
                />
                {Status.map((s, i) => (
                  <Tab
                    eventKey={s._id}
                    key={i}
                    title={s.label + " (" + s.count + ")"}
                  />
                ))}
              </Tabs>
            </div>
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th nowrap="true">
                      <input
                        type="checkbox"
                        name="select"
                        onClick={(e) =>
                          SelectAllRow(e, "opp_check", setActionInfo)
                        }
                      />
                    </th>
                    <th nowrap="true">Opportunity Number</th>
                    {/* <th nowrap="true">Prospects</th> */}
                    <th nowrap="true" width="15%">
                      Status
                    </th>
                    {state.columns &&
                      state.columns.map((column, i) => (
                        <th key={i} style={{ whiteSpace: "nowrap" }}>
                          {column.title}
                          {[
                            "first_name",
                            "last_name",
                            "email",
                            "phone",
                          ].includes(column.id) && (
                            <Sort
                              data={sort}
                              actions={{ sort: setSort, load: setLoading }}
                              field={"info." + column.id}
                            />
                          )}
                        </th>
                      ))}
                    <th nowrap="true">Assigned Team Members</th>
                    {/* <th nowrap="true">Assigned Workforces</th>
                    <th nowrap="true">Assigned Contacts</th> */}
                    {/*<th nowrap="true">
                      Created Date
                      <Sort
                        data={sort}
                        actions={{ sort: setSort, load: setLoading }}
                        field="createdAt"
                      />
                    </th>
                    <th nowrap="true">Created By</th>
                    <th nowrap="true">
                      Updated Date
                      <Sort
                        data={sort}
                        actions={{ sort: setSort, load: setLoading }}
                        field="updatedAt"
                      />
                    </th>
                  <th nowrap="true">Updated By</th>*/}
                    <th nowrap="true">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {opportunities.length > 0 ? (
                    opportunities.map((opportunity, i) => (
                      <tr
                        key={i}
                        style={
                          changedRecords["Opportunity"] &&
                          changedRecords["Opportunity"].includes(
                            opportunity._id.toString()
                          )
                            ? { backgroundColor: "#E77857" }
                            : { backgroundColor: "" }
                        }
                      >
                        <td>{currentRowStart + i}</td>
                        <td>
                          <input
                            type="checkbox"
                            className="opp_check"
                            onClick={(e) =>
                              SelectOneRow(e, setActionInfo, actionInfo)
                            }
                            value={opportunity._id}
                          />
                        </td>
                        <td nowrap="true">
                          <JumbLink
                            position={permissions.update ? 0 : 1}
                            url={"/opportunities/edit/" + opportunity._id}
                          />
                          <Link to={"/opportunities/edit/" + opportunity._id}>
                            {opportunity.company.prefix &&
                            opportunity.program &&
                            !!opportunity.company.prefix[
                              opportunity.program._id
                            ]
                              ? opportunity.company.prefix[
                                  opportunity.program._id
                                ]
                              : ""}
                            {opportunity.info
                              ? opportunity.info.auto_increment
                              : ""}
                          </Link>
                        </td>
                        {/* <td>
                          {opportunity?.prospects.map((pro) => (
                            <span key={pro._id}>
                              {pro?.info?.first_name +
                                " " +
                                pro?.info?.last_name}
                              <br />
                            </span>
                          ))}
                          <Link
                            to={`/opportunities/edit/${opportunity._id}/?tab=admin`}
                          >
                            <i
                              className="fa fa-edit fa-lg text-primary ml-2"
                              title="Click to update status"
                            />
                          </Link>
                        </td> */}
                        <td nowrap="true">
                          {props.user.type === "Workforces" ? (
                            <StatusUpdate
                              data={opportunity}
                              status={Status}
                              setMsg={setMsg}
                            />
                          ) : (
                            opportunity.status &&
                            opportunity.status.map((su, ss) => (
                              <span key={ss}>
                                {su.title}
                                <br />
                              </span>
                            ))
                          )}
                        </td>
                        {state.columns &&
                          state.columns.map((column, i) => (
                            <td key={i}>
                              {column.id === "status" && opportunity.status
                                ? opportunity.status.map((s, i) => (
                                    <span key={i}>
                                      {s.title}
                                      <br />
                                    </span>
                                  ))
                                : opportunity.info
                                ? column.options
                                  ? Array.isArray(opportunity.info[column.id])
                                    ? column.type === "checkbox"
                                      ? column.options.map((option, i) => {
                                          return (
                                            opportunity.info[
                                              column.id
                                            ].includes(option.id) && (
                                              <span key={i}>
                                                {option.text}
                                                <br />
                                              </span>
                                            )
                                          );
                                        })
                                      : opportunity.info[column.id].map(
                                          (val, i) => (
                                            <span key={i}>
                                              {val.text}
                                              <br />
                                            </span>
                                          )
                                        )
                                    : column.options.map(
                                        (option, i) =>
                                          opportunity.info[column.id] ===
                                            option.id && (
                                            <span key={i}>
                                              {option.text}
                                              <br />
                                            </span>
                                          )
                                      )
                                  : column.type === "date"
                                  ? getDate(opportunity.info[column.id])
                                  : opportunity.info[column.id]
                                : ""}
                            </td>
                          ))}
                        <td>
                          {props.user.type === "Workforces" ? (
                            <AssignedTeamMembers
                              users={wusers}
                              data={opportunity}
                              setMsg={setMsg}
                              asUsers={opportunity.workforces}
                            />
                          ) : (
                            opportunity.workforces &&
                            opportunity.workforces.map((w, ii) => (
                              <span key={ii}>
                                {w.user && w.user.info && w.user.info.first_name
                                  ? w.user.info.first_name
                                  : ""}{" "}
                                {w.user && w.user.info && w.user.info.last_name
                                  ? w.user.info.last_name
                                  : ""}
                                {w.role ? "(" + w.role.title + ")" : ""}
                                <br />
                              </span>
                            ))
                          )}
                        </td>
                        {/* <td>
                          {opportunity.workforces.map(
                            (u, i) =>
                              u.user && (
                                <span key={i} className="mb-1">
                                  {u.user
                                    ? u.user.info.first_name +
                                      " " +
                                      u.user.info.last_name
                                    : " " +
                                      (" (" +
                                        (u.role ? u.role.title : "") +
                                        ")")}
                                  <br />
                                </span>
                              )
                          )}
                        </td>
                        <td>
                          {opportunity.contacts.map(
                            (u, i) =>
                              u.user && (
                                <span key={i} className="mb-1">
                                  {u.user
                                    ? u.user.info.first_name +
                                      " " +
                                      u.user.info.last_name
                                    : " " +
                                      (" (" +
                                        (u.role ? u.role.title : "") +
                                        ")")}
                                  <br />
                                </span>
                              )
                          )}
                        </td> */}
                        {/*<td>{opportunity.createdAt}</td>
                        <td>{opportunity.created_by.user}</td>
                        <td>{opportunity.updatedAt}</td>
                      <td>{opportunity.updated_by.user}</td>*/}
                        <td nowrap="true">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Click here to submit new Prospect
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/forms/${state.webformId}?au=${opportunity._id}`}
                            >
                              <MdAddLink size={30} className="mr-1" />
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                Created by :{" "}
                                <strong>{opportunity.created_by.user}</strong>
                                <br />
                                Created Date & Time:{" "}
                                <strong>{opportunity.createdAt}</strong>
                                <br />
                                Last Updated by:{" "}
                                <strong>{opportunity.updated_by.user}</strong>
                                <br />
                                Last Updated Date & Time:{" "}
                                <strong>
                                  {!!opportunity.updated_by.user
                                    ? opportunity.updatedAt
                                    : ""}
                                </strong>
                              </Tooltip>
                            }
                          >
                            <i className="fa fa-info-circle fa-lg text-primary mr-2" />
                          </OverlayTrigger>
                          {permissions.compose !== false && (
                            <i
                              className="fa fa-envelope fa-lg text-warning mr-2 ml-2"
                              onClick={() =>
                                composeEmailClickHandler(opportunity, "Email")
                              }
                            />
                          )}
                          {permissions.delete && (
                            <span className="ml-2">
                              {opportunity.active ? (
                                <Deactivate
                                  url={backendApiUrl(
                                    "opportunities/" + opportunity._id
                                  )}
                                  id={opportunity._id}
                                  actions={{
                                    msg: setMsg,
                                    load: setLoading,
                                  }}
                                  title={""}
                                />
                              ) : (
                                <Activate
                                  url={backendApiUrl("opportunities/activate")}
                                  id={opportunity._id}
                                  actions={{
                                    msg: setMsg,
                                    load: setLoading,
                                  }}
                                  title={""}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <h5 className="text-primary text-center">
                          No opportunities available, Create one!
                        </h5>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      colSpan={state.columns ? state.columns.length + 14 : 14}
                      count={totalDocumnetCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "Rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </tr>
                </tfoot>
              </table>
            </div>
          </>
        )}

        {actionInfo.openCompose === true && (
          <ComposeEmail
            info={actionInfo}
            module="Opportunities"
            filters={filters}
            actions={{ setMsg, setActionInfo }}
          />
        )}
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
export default connect(mapStateToProps)(List);

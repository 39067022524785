import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { metronic } from "../../_metronic";
import { backendApiUrl } from "../utils";
// import { MODULE_CONFIG } from "./config/Module";
// import { OPPORTUNITY_CONFIG } from "./config/Opportunity";
// import { WEBFORM_CONFIG } from "./config/WebForm";

const Footer = () => {
  const dispatch = useDispatch();

  const {
    moduleBuilderConfig,
    opportunityBuilderConfig,
    webformBuilderConfig,
    libraryBuilderConfig,
  } = useSelector(
    ({ builder }) => ({
      moduleBuilderConfig: builder.moduleBuilderConfig,
      opportunityBuilderConfig: builder.opportunityBuilderConfig,
      webformBuilderConfig: builder.webformBuilderConfig,
      libraryBuilderConfig: builder.libraryBuilderConfig,
    }),
    shallowEqual
  );

  // const resetBtnClickHandler = (event) => {
  //   switch (window.location.pathname) {
  //     case "/builders/modules/form":
  //       let sconfig = { ...moduleBuilderConfig };
  //       sconfig.selectedId = MODULE_CONFIG.selectedId;
  //       sconfig.parentId = MODULE_CONFIG.parentId;
  //       sconfig.content = [...MODULE_CONFIG.content];
  //       sconfig.msg = {
  //         status: 200,
  //         msg: "Module data cleared successfully.",
  //         show: true,
  //       };
  //       dispatch(metronic.builder.actions.setModuleBuilder(sconfig));
  //       break;
  //     case "/builders/opportunity/form":
  //       let pconfig = { ...opportunityBuilderConfig };
  //       pconfig.selectedId = OPPORTUNITY_CONFIG.selectedId;
  //       pconfig.parentId = OPPORTUNITY_CONFIG.parentId;
  //       pconfig.content = [...OPPORTUNITY_CONFIG.content];
  //       pconfig.msg = {
  //         status: 200,
  //         msg: "App data cleared successfully.",
  //         show: true,
  //       };
  //       dispatch(metronic.builder.actions.setOpportunityBuilder(pconfig));
  //       break;
  //     case "/builders/webforms/form":
  //       let wconfig = { ...webformBuilderConfig };
  //       wconfig.selectedId = WEBFORM_CONFIG.selectedId;
  //       wconfig.parentId = WEBFORM_CONFIG.parentId;
  //       wconfig.content = [...WEBFORM_CONFIG.content];
  //       wconfig.msg = {
  //         status: 200,
  //         msg: "Webform data cleared successfully.",
  //         show: true,
  //       };
  //       dispatch(metronic.builder.actions.setWebformBuilder(wconfig));
  //       break;
  //     case "/builders/libraries/form":
  //       let twconfig = { ...libraryBuilderConfig };
  //       twconfig.selectedId = WEBFORM_CONFIG.selectedId;
  //       twconfig.parentId = WEBFORM_CONFIG.parentId;
  //       twconfig.content = [...WEBFORM_CONFIG.content];
  //       twconfig.msg = {
  //         status: 200,
  //         msg: "Libraries data cleared successfully.",
  //         show: true,
  //       };
  //       dispatch(metronic.builder.actions.setLibraryBuilder(twconfig));
  //       break;
  //     default:
  //       break;
  //   }
  //   window.scrollTo(0, 0);
  // };

  const updateAndPublishBtnClickHandler = async (event) => {
    event.preventDefault();
    let data = {};
    let msg = "";
    switch (window.location.pathname) {
      case "/builders/modules/form":
        data = { ...moduleBuilderConfig };
        msg = "Module updated successfully.";
        break;
      case "/builders/opportunity/form":
        data = { ...opportunityBuilderConfig };
        msg = "Opportunity updated successfully.";
        break;
      case "/builders/webforms/form":
        data = { ...webformBuilderConfig };
        msg = "Webform updated successfully.";
        break;
      case "/builders/libraries/form":
        data = { ...libraryBuilderConfig };
        msg = "Libraries updated successfully.";
        break;
      default:
        break;
    }

    await axios
      .patch(backendApiUrl("forms/" + data._id), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let config = { ...data };
        config.msg = {
          status: 200,
          msg,
          show: true,
        };
        switch (window.location.pathname) {
          case "/builders/modules/form":
            dispatch(metronic.builder.actions.setModuleBuilder(config));
            break;
          case "/builders/opportunity/form":
            dispatch(metronic.builder.actions.setOpportunityBuilder(config));
            break;
          case "/builders/webforms/form":
            dispatch(metronic.builder.actions.setWebformBuilder(config));
            break;
          case "/builders/libraries/form":
            dispatch(metronic.builder.actions.setLibraryBuilder(config));
            break;
          default:
            break;
        }
        window.scrollTo(0, 0);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        let config = { ...data };
        config.msg = {
          status: 200,
          msg: "Something went wrong, could not update.",
          show: true,
        };
        switch (window.location.pathname) {
          case "/builders/modules/form":
            dispatch(metronic.builder.actions.setModuleBuilder(config));
            break;
          case "/builders/opportunity/form":
            dispatch(metronic.builder.actions.setOpportunityBuilder(config));
            break;
          case "/builders/webforms/form":
            dispatch(metronic.builder.actions.setWebformBuilder(config));
            break;
          case "/builders/libraries/form":
            dispatch(metronic.builder.actions.setLibraryBuilder(config));
            break;
          default:
            break;
        }
        window.scrollTo(0, 0);
      });
  };

  return (
    <div
      className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop kt-footer--fixed"
      id="kt_footer"
    >
      <div className="col-md-12">
        <div className="kt-footer__copyright"></div>
        <div className="kt-footer__menu">
          {/* <div style={{ marginRight: "3px", marginBottom: "5px" }}>
            <button
              type="button"
              className="btn btn-default"
              onClick={resetBtnClickHandler}
            >
              <i className="la la-refresh" aria-hidden="true"></i> Reset
            </button>
          </div> */}
          <div style={{ marginBottom: "5px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateAndPublishBtnClickHandler}
            >
              <i className="la la-globe" /> Update & Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

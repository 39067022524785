import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ComposeEmail from "../services/email/Compose";
import { backendApiUrl } from "../utils";

const EmailFilter = (props) => {
  const [state, setState] = useState({
    compose_module: "",
    load: true,
  });
  const [actionInfo, setActionInfo] = useState({});

  const onChangeHandler = (e) => {
    let st = { ...props.state };
    st.filter[e.target.name] = e.target.value;
    props.setState(st);
  };

  const composeEmailClickHandler = (user, from) => {
    let data = { from, openCompose: true };
    data.owner = user.MDID;
    let label = "";
    let Module = "";

    let st = { ...state };
    if (!!user.dynamicFieldData.first_name)
      label = user.dynamicFieldData.first_name;
    if (!!user.dynamicFieldData.last_name)
      label += " " + user.dynamicFieldData.last_name;
    label += " (" + user.dynamicFieldData.email + ")";
    data.from = label;
    if (user.from === "leads") {
      Module = "Leads";
    } else if (user.from === "opportunities") {
      Module = "Opportunities";
    } else {
      Module = user.from;
    }
    data.module = Module;
    st.compose_module = Module;
    setState(st);
    data.recipients = [
      {
        label,
        email: user.dynamicFieldData.email,
        value: user.MDID,
        type: user.from,
      },
    ];
    data.url = backendApiUrl("emails/populate_tags");
    setActionInfo(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <div className="form-group">
          <label className="form-label" htmlFor="email_type">
            Email Type:
          </label>
          <select
            className="form-control"
            name="email_type"
            value={props.state.filter.email_type}
            onChange={onChangeHandler}
          >
            <option value="">All Mails</option>
            <option value="send">Send Mails</option>
            <option value="delivery">Delivered Mails</option>
            <option value="open">Opened Mails</option>
            <option value="click">Clicked Mails</option>
            <option value="bounce">Bounced Mails</option>
            <option value="unsubscribe">Unsubscribed Mails</option>
          </select>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="form-group">
          <label className="form-label" htmlFor="sdate">
            Date range:
          </label>
          <input
            type="date"
            name="sdate"
            className="form-control"
            value={props.state.filter.sdate}
            onChange={onChangeHandler}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="form-group">
          <label className="form-label" htmlFor="edate">
            &nbsp;
          </label>
          <input
            type="date"
            name="edate"
            className="form-control"
            value={props.state.filter.edate}
            onChange={onChangeHandler}
          />
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className="form-group">
          <button
            className="btn btn-sm btn-primary"
            style={{ marginTop: "28px" }}
            onClick={() => props.setLoad(true)}
          >
            Search
          </button>
        </div>
      </Grid>
      <Grid item xs={3}></Grid>
      {props && !!props.state.MI ? (
        <Grid item xs={1}>
          <div className="form-group">
            <button
              className="btn btn-sm btn-primary"
              style={{ marginTop: "28px", marginLeft: "-10px" }}
              onClick={() =>
                composeEmailClickHandler(
                  props.state.MI ? props.state.MI : {},
                  "Email"
                )
              }
            >
              Compose Mail
            </button>
          </div>
        </Grid>
      ) : (
        ""
      )}
      {actionInfo.openCompose === true && (
        <ComposeEmail
          info={actionInfo}
          module={state.compose_module}
          filters={state.filters}
          actions={{
            setMsg: props.setMsg,
            setActionInfo,
            setLoad: props.setLoad,
          }}
        />
      )}
    </Grid>
  );
};

export default EmailFilter;

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import { Modal } from "react-bootstrap";

import { backendApiUrl } from "../utils";

const AssignedProspect = ({
  opportunityId,
  prospects,
  openPopup,
  selected,
  setMsg,
  onHandleSave,
}) => {
  const [selectProspect, setSelectProspect] = useState(selected);

  const handleSave = async () => {
    await axios
      .post(
        backendApiUrl("opportunities/handle_prospect"),
        { opportunityId, users: selectProspect },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        openPopup(false);
        onHandleSave(true);
        let msg = {
          status: res.status,
          msg: res.data.msg,
          show: true,
        };
        setMsg(msg);
      });
  };

  return (
    <Modal
      show={true}
      size="md"
      onHide={() => openPopup(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assigning Prospects</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Grid item xs={12}>
          <label className="form-label" htmlFor="prospects">
            Prospects
          </label>
          <Select
            className="kt-width-full"
            name="prospects"
            options={prospects}
            onChange={setSelectProspect}
            value={selectProspect}
            isMulti
            placeholder="Please select prospects"
          />
        </Grid>
        <button
          className="mt-2 btn btn-primary"
          align="right"
          onClick={handleSave}
        >
          Update
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AssignedProspect;

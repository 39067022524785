import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Modal, Spinner, Button } from "react-bootstrap";

import { backendApiUrl } from "../utils";
// import List from "./list";
import Message from "../utils/Message";
import Notes from "../services/notes";
import Contracts from "./contracts/index";
import Assets from "./assets/index";
// import SummaryDocs from "./SummaryDocs";
import StandardDocs from "./standard_docs/index";

const DocumentManager = (props) => {
  let hideFields = [];
  if (
    props.module.form.hideSectionsAndFields &&
    props.module.form.hideSectionsAndFields.length > 0
  ) {
    hideFields = props.module.form.hideSectionsAndFields;
  }

  let form_id = props.module.form._id;

  let owner_id = props.module.MDID;

  const [state, setState] = useState({ load: true });

  const [msg, setMsg] = useState({});

  const noteOnClickHandler = (doc_id) => {
    let st = { ...state };
    st.doc_id = doc_id;
    setState(st);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("public/docs"),
          { form_id, owner_id, hideFields },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state };
          st.modules = res.data.modules;
          st.templates = res.data.templates;
          st.company = res.data.company_name;
          if (res.data.logo) st.logo = res.data.logo;
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
  }, [form_id, owner_id, state, hideFields]);

  return (
    <div>
      {msg.show && (
        <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
      )}

      {props.module.from === "opportunities" &&
        props.module.permissions &&
        props.module.permissions.payments &&
        props.module.permissions.payments.add && (
          <Assets
            setMsg={setMsg}
            opportunity={props.module.opportunity}
            owner={owner_id}
            user={props.user}
          />
        )}

      {state.load ? (
        <div style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          {/* {state.modules &&
            state.modules.map(
              (m, i) =>
                m.fields.length > 0 && (
                  <List
                    module={m}
                    key={i}
                    MI={props.module}
                    events={{ ...props.events, noteOnClickHandler, setMsg }}
                  />
                )
            )} */}

          {props.user && props.user.type === "Workforces" && (
            <Contracts templates={state.templates} MI={props.module} />
          )}

          {props.user &&
            props.user.type === "Workforces" &&
            props.module.from !== "leads" && (
              <>
                <div className="col-md-12">
                  <StandardDocs MI={props.module} />
                </div>
              </>
            )}
          {/* {props.module.form.type === "webform" && (
            <SummaryDocs MI={props.module} />
          )} */}

          {!!state.doc_id && (
            <Modal show={true} size="xl" backdrop="static" keyboard={false}>
              <Modal.Body>
                <Notes
                  module="docs"
                  permissions={{ notes: { add: true, update: true } }}
                  owner={state.doc_id}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => noteOnClickHandler("")}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(DocumentManager);

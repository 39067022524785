import React from "react";
import { FormattedMessage } from "react-intl";

export default class MenuItemText extends React.Component {
  render() {
    const { item } = this.props;
    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

        <span className="kt-menu__link-text" style={{ wordWrap: "break-word" }}>
          {!item.translate ? (
            item.title
          ) : (
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          )}
        </span>

        {item.isDrag && (
          <i
            className="kt-menu__link-bullet fa fa-arrow-right fa-lg"
            onClick={this.props.onClick}
          >
            <span />
          </i>
        )}
        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
      </>
    );
  }
}

import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import clsx from "clsx";
import { connect } from "react-redux";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";

import { metronic } from "../../../_metronic";
import { backendApiUrl } from "../../utils";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const UserNotifications = (props) => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("All");
  const [state, setState] = useState({ load: true, count: 0, data: [] });
  let iconType = "brand";

  const getHetBackGroundCssClassList = () => {
    let result = "kt-head ";
    if (props.skin) {
      result += `kt-head--skin-${props.skin} `;
    }

    result += "kt-head--fit-x kt-head--fit-b";
    return result;
  };

  const backGroundStyle = () => {
    if (!props.bgImage) {
      return "none";
    }

    return "url(" + props.bgImage + ")";
  };

  const ulTabsClassList = () => {
    let result = "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ";
    if (props.type) {
      result += `nav-tabs-line-${props.type} `;
    }

    result += "kt-notification-item-padding-x";
    return result;
  };

  const viewAllClickHandler = () => {
    axios
      .post(
        backendApiUrl("notifications/view"),
        { type: key },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let st = { ...state, ...res.data };
        st.load = true;
        setState(st);
      })
      .catch((err) => {
        let st = { ...state };
        st.load = false;
        setState(st);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      let st = { ...state };
      st.load = true;
      setState(st);
    }, 50000);
    //assign interval to a variable to clear it.
    if (props.user.type === "Contacts" && state.load) {
      let st = { ...state };
      st.load = false;
      setState(st);
    } else if (state.load) {
      axios
        .post(
          backendApiUrl("notifications"),
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let rd = { ...res.data };
          let st = { ...state, ...rd };
          let changedRecords = {};
          rd.data &&
            rd.data.forEach((d) => {
              if (d.module === "Opportunity") {
                if (changedRecords["Opportunity"]) {
                  changedRecords["Opportunity"] = [
                    ...changedRecords["Opportunity"],
                    d.owner,
                  ];
                } else {
                  changedRecords["Opportunity"] = [d.owner];
                }
              } else {
                if (changedRecords[d.form]) {
                  changedRecords[d.form] = [...changedRecords[d.form], d.owner];
                } else {
                  changedRecords[d.form] = [d.owner];
                }
              }
              if (d.parent) {
                if (changedRecords[d.parent]) {
                  changedRecords[d.parent] = [
                    ...changedRecords[d.parent],
                    d.parent,
                  ];
                } else {
                  changedRecords[d.parent] = [d.parent];
                }
              }
            });

          dispatch(metronic.builder.actions.setChangedRecords(changedRecords));
          st.load = false;
          setState(st);
        })
        .catch((err) => {
          console.log(err);
          let st = { ...state };
          st.load = false;
          setState(st);
        });
    }
    return () => clearInterval(intervalId); //This is important
  }, [dispatch, props.user.type, state]);

  return (
    <Fragment>
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down"
        alignRight
      >
        {props.user.type === "Workforces" && (
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-my-cart"
          >
            <span
              className={clsx("kt-header__topbar-icon", {
                "kt-header__topbar-icon--brand": iconType === "brand",
              })}
            >
              {state.count > 0 && (
                <span
                  className="bg-danger"
                  style={{
                    height: "10px",
                    borderRadius: "50%",
                    width: "10px",
                    textAlign: "center",
                    paddingTop: "1px",
                    color: "#fff",
                  }}
                >
                  <b>{state.count}</b>
                </span>
              )}
              <i
                className="fa fa-bell fa-2x actionicon"
                style={{ color: state.count > 0 ? "red" : "#fff" }}
              />
            </span>
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            <div
              className={getHetBackGroundCssClassList()}
              style={{ backgroundImage: backGroundStyle() }}
            >
              <div className="row">
                <div className="col-6 ml-3">
                  <h3>Notifications</h3>
                </div>
                <div className="col-5" style={{ textAlign: "right" }}>
                  {state.count > 0 && (
                    <span
                      className="fa text-primary"
                      onClick={viewAllClickHandler}
                    >
                      <u>Clear {key}</u>
                    </span>
                  )}
                </div>
              </div>

              <Tab.Container
                defaultActiveKey={key}
                className={ulTabsClassList()}
              >
                <Nav
                  className={ulTabsClassList()}
                  onSelect={(_key) => setKey(_key)}
                >
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="All" className="nav-link show">
                      All
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="Assigned" className="nav-link show">
                      Assigned
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="Docs" className="nav-link show">
                      Docs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="Reviews" className="nav-link show">
                      Data Reviews
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="All">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      {state.data.length > 0 ? (
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10"
                          style={{ maxHeight: "40vh", position: "relative" }}
                        >
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {state.data.map((d, i) => (
                              <span className="kt-notification__item" key={i}>
                                <div className="kt-notification__item-icon">
                                  {d.type === "update" && (
                                    <i className="flaticon2-pen kt-font-success" />
                                  )}
                                  {d.type === "delete" && (
                                    <i className="flaticon-delete kt-font-success" />
                                  )}
                                  {d.type === "create" && (
                                    <i className="flaticon-user-add kt-font-success" />
                                  )}
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: d.msg,
                                      }}
                                    />
                                    .
                                    <h6>
                                      <Link to={d.url}>
                                        Click to open {d.module}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {d.time}
                                  </div>
                                </div>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="kt-grid kt-grid--ver"
                          style={{ minHeight: "200px" }}
                        >
                          <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                            <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                          </div>
                        </div>
                      )}
                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Assigned">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      {state.Assigned && state.Assigned.length > 0 ? (
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10"
                          style={{ maxHeight: "40vh", position: "relative" }}
                        >
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {state.Assigned.map((d, i) => (
                              <span className="kt-notification__item" key={i}>
                                <div className="kt-notification__item-icon">
                                  {d.type === "update" && (
                                    <i className="flaticon2-pen kt-font-success" />
                                  )}
                                  {d.type === "delete" && (
                                    <i className="flaticon-delete kt-font-success" />
                                  )}
                                  {d.type === "create" && (
                                    <i className="flaticon-user-add kt-font-success" />
                                  )}
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: d.msg,
                                      }}
                                    />
                                    .
                                    <h6>
                                      <Link to={d.url}>
                                        Click to open {d.module}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {d.time}
                                  </div>
                                </div>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="kt-grid kt-grid--ver"
                          style={{ minHeight: "200px" }}
                        >
                          <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                            <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                          </div>
                        </div>
                      )}
                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Docs">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      {state.Docs && state.Docs.length > 0 ? (
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10"
                          style={{ maxHeight: "40vh", position: "relative" }}
                        >
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {state.Docs.map((d, i) => (
                              <span className="kt-notification__item" key={i}>
                                <div className="kt-notification__item-icon">
                                  {d.type === "update" && (
                                    <i className="flaticon2-pen kt-font-success" />
                                  )}
                                  {d.type === "delete" && (
                                    <i className="flaticon-delete kt-font-success" />
                                  )}
                                  {d.type === "create" && (
                                    <i className="flaticon-user-add kt-font-success" />
                                  )}
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: d.msg,
                                      }}
                                    />
                                    .
                                    <h6>
                                      <Link to={d.url}>
                                        Click to open {d.module}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {d.time}
                                  </div>
                                </div>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="kt-grid kt-grid--ver"
                          style={{ minHeight: "200px" }}
                        >
                          <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                            <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                          </div>
                        </div>
                      )}
                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Reviews">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      {state.Reviews && state.Reviews.length > 0 ? (
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10"
                          style={{ maxHeight: "40vh", position: "relative" }}
                        >
                          <div
                            className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                            data-scroll="true"
                            data-height="300"
                            data-mobile-height="200"
                          >
                            {state.Reviews.map((d, i) => (
                              <span className="kt-notification__item" key={i}>
                                <div className="kt-notification__item-icon">
                                  {d.type === "update" && (
                                    <i className="flaticon2-pen kt-font-success" />
                                  )}
                                  {d.type === "delete" && (
                                    <i className="flaticon-delete kt-font-success" />
                                  )}
                                  {d.type === "create" && (
                                    <i className="flaticon-user-add kt-font-success" />
                                  )}
                                </div>
                                <div className="kt-notification__item-details">
                                  <div className="kt-notification__item-title">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: d.msg,
                                      }}
                                    />
                                    .
                                    <h6>
                                      <Link to={d.url}>
                                        Click to open {d.module}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="kt-notification__item-time">
                                    {d.time}
                                  </div>
                                </div>
                              </span>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="kt-grid kt-grid--ver"
                          style={{ minHeight: "200px" }}
                        >
                          <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                            <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                              All caught up!
                              <br />
                              No new notifications.
                            </div>
                          </div>
                        </div>
                      )}
                    </PerfectScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
export default connect(mapStateToProps)(UserNotifications);

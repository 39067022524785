import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

import "./mailbox.css";
import Counter from "./Counter";
import EmailFilter from "./EmailFilter";
import { backendApiUrl, getDate } from "../utils";
import Sort from "../utils/Sort";
import { TablePagination } from "@material-ui/core";
import TablePaginationActions from "../shared/TablePaginationActions";

const Campaign = (props) => {
  const [state, setState] = useState({
    emails: [],
    campaign_id: useParams().campaign_id,
    filter: !!props.module
      ? {
          module:
            props.opp && props.opp.prospect ? "Opportunities" : props.module,
          owner:
            props.opp && props.opp.prospect
              ? props.opp.prospect._id
              : props.owner,
          email: props.email,
        }
      : {},
    load: true,
    MI: props.MI,
  });

  const setLoad = (status) => {
    let st = { ...state };
    st.load = status;
    setState(st);
  };

  const onCountClickHandler = (field) => {
    let st = { ...state };
    st.filter["email_type"] = field;
    st.load = true;
    setState(st);
  };

  const [sort, setSort] = useState({});

  const [totalDocumnetCount, setTotalDocumnetCount] = useState(0);

  const [currentRowStart, setCurrentRowStart] = useState(1);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (
      Math.max(0, Math.ceil(totalDocumnetCount / rowsPerPage) - 1) === newPage
    ) {
      setCurrentRowStart(totalDocumnetCount - rowsPerPage + 1);
    } else if (page < newPage) {
      setCurrentRowStart(currentRowStart + rowsPerPage);
    } else if (newPage === 0) {
      setCurrentRowStart(1);
    } else {
      setCurrentRowStart(currentRowStart - rowsPerPage);
    }
    setPage(newPage);
    setLoad(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoad(true);
  };

  useEffect(() => {
    if (state.load) {
      let data = {
        skip: currentRowStart - 1,
        limit: rowsPerPage,
      };

      if (sort.field) data.sort = sort;
      if (state.filter) data.search = state.filter;
      if (state.campaign_id) data._id = state.campaign_id;
      if (state.MI && state.MI.form) data.module_name = state.MI.form.title;

      axios
        .post(
          backendApiUrl("campaign/emails"),
          data,
          { _id: state.campaign_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, ...res.data };
          st.load = false;
          setState(st);
          setTotalDocumnetCount(res.data.count);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, currentRowStart, rowsPerPage, sort]);

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <div className="p-3">
      {state.emails.length > 0 && !props.module && (
        <span>
          <h4>
            Campaign:
            <span className="ml-1">{state.emails[0].campaign.title}</span>
          </h4>
        </span>
      )}
      <Counter state={state} module={!!state.MI?state.MI.form.title:"Campaign"} onClickHandler={onCountClickHandler} />
      <hr />
      <EmailFilter
        setLoad={setLoad}
        state={state}
        setState={setState}
        setMsg={props.setMsg}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Sender</th>
                  <th>Recipient</th>
                  <th>Subject</th>
                  <th>sent</th>
                  <th>Delivery</th>
                  <th>Open</th>
                  <th>Click</th>
                  <th>Bounce</th>
                  <th>Unsubscribed</th>
                  <th>
                    Date
                    <Sort
                      data={sort}
                      actions={{ sort: setSort, load: setLoad }}
                      field="createdAt"
                    />
                  </th>
                  <th>Mail Type</th>
                </tr>
              </thead>
              <tbody>
                {state.emails &&
                  state.emails.map((c, i) => (
                    <tr key={i}>
                      <td>{currentRowStart + i}</td>
                      <td>
                        {c.general_mail && <>{c.general_mail.from_mail}</>}
                        {c.campaign &&
                          c.campaign.from_name +
                            " | " +
                            c.campaign.from_email.value}
                      </td>
                      <td>{c.recipient.email}</td>
                      <td>
                        {c.general_mail && <>{c.general_mail.subject}</>}
                        {c.campaign && c.campaign.subject}
                      </td>
                      <td>
                        {c.send && (
                          <>
                            <i className="fa fa-check" />
                            <br />
                            {getDate(c.send.mail.timestamp)}
                          </>
                        )}
                      </td>
                      <td>
                        {c.delivery && (
                          <>
                            <i className="fa fa-check-double" />
                            <br />
                            {getDate(c.delivery.mail.timestamp)}
                          </>
                        )}
                      </td>
                      <td>
                        {c.open && (
                          <>
                            <i
                              className="fa fa-check-double"
                              style={{ color: "blue" }}
                            />
                            <br />
                            {getDate(c.open.mail.timestamp)}
                          </>
                        )}
                      </td>
                      <td>
                        {c.click && (
                          <>
                            <i className="fa fa-check-double" />
                            <br />
                            {getDate(c.click.mail.timestamp)}
                          </>
                        )}
                      </td>
                      <td>
                        {c.bounce && <>{getDate(c.bounce.mail.timestamp)}</>}
                      </td>
                      <td>
                        {c.unsubscribed && <>{getDate(c.unsubscribed.date)}</>}
                      </td>
                      <td>{c.createdAt}</td>
                      <td>
                        {" "}
                        {c.general_mail && <>General</>}
                        {c.campaign && <>Campaign</>}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={12}
                    count={totalDocumnetCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Campaign;

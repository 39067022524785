import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
//import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import MirsLogin from "./MirsLogin";
import BLLogin from "./BranchLendingLogin";

export default function AuthPage() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          style={{ padding: "0px" }}
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            {(window.location.hostname !== "quote.mirsgroupllc.com" && window.location.hostname !== "portal.branchlending.com") && (
              <div
                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/bg/home.jpg"
                  )})`,
                }}
              >
                <div className="kt-grid__item"></div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                  <div className="kt-grid__item kt-grid__item--middle"></div>
                </div>
              </div>
            )}
            <div
              className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
              style={
                window.location.hostname === "quote.mirsgroupllc.com"
                  ? { paddingTop: "0px" }
                  : {}
              }
            >
              <Switch>
                {window.location.hostname === "quote.mirsgroupllc.com" ? (
                  <Route path="/auth/login" component={MirsLogin} />
                ) : window.location.hostname === "portal.branchlending.com" ? <Route path="/auth/login" component={BLLogin} /> : (
                  <Route path="/auth/login" component={Login} />
                )}
                {/* <Route path="/auth/registration" component={Registration} /> */}
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/auth/reset-password/:user_id"
                  component={ResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

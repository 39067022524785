// const NonInputElements = [
//   "container",
//   "grid",
//   "section",
//   "tab",
//   "item",
//   "auto_increment",
//   "file",
//   "image",
//   "signature",
//   "button",
//   "headertext",
//   "separator",
//   "paragraph",
// ];

/**
 * Calculation.
 */
class Calculation {
  constructor() {
    this._symbols = {};
    this.defineOperator("!", this.factorial, "postfix", 6);
    this.defineOperator("^", Math.pow, "infix", 5, true);
    this.defineOperator("*", this.multiplication, "infix", 4);
    this.defineOperator("/", this.division, "infix", 4);
    this.defineOperator("+", this.last, "prefix", 3);
    this.defineOperator("-", this.negation, "prefix", 3);
    this.defineOperator("+", this.addition, "infix", 2);
    this.defineOperator("-", this.subtraction, "infix", 2);
    this.defineOperator(",", Array.of, "infix", 1);
    this.defineOperator("(", this.last, "prefix");
    this.defineOperator(")", null, "postfix");
    this.defineOperator("min", Math.min);
    this.defineOperator("sqrt", Math.sqrt);
  }
  // Method allowing to extend an instance with more operators and functions:
  defineOperator(
    symbol,
    f,
    notation = "func",
    precedence = 0,
    rightToLeft = false
  ) {
    // Store operators keyed by their symbol/name. Some symbols may represent
    // different usages: e.g. "-" can be unary or binary, so they are also
    // keyed by their notation (prefix, infix, postfix, func):
    if (notation === "func") precedence = 0;
    this._symbols[symbol] = Object.assign({}, this._symbols[symbol], {
      [notation]: {
        symbol,
        f,
        notation,
        precedence,
        rightToLeft,
        argCount: 1 + (notation === "infix"),
      },
      symbol,
      regSymbol:
        symbol.replace(/[\\^$*+?.()|[\]{}]/g, "\\$&") +
        (/\w$/.test(symbol) ? "\\b" : ""), // add a break if it's a name
    });
  }
  last(...a) {
    return a[a.length - 1];
  }
  negation(a) {
    return -a;
  }
  addition(a, b) {
    return a + b;
  }
  subtraction(a, b) {
    return a - b;
  }
  multiplication(a, b) {
    return a * b;
  }
  division(a, b) {
    return a / b;
  }
  factorial(a) {
    if (a % 1 || !(+a >= 0)) return NaN;
    if (a > 170) return Infinity;
    let b = 1;
    while (a > 1) b *= a--;
    return b;
  }
  calculate(expression) {
    let match;
    const values = [],
      operators = [this._symbols["("].prefix],
      exec = (_) => {
        let op = operators.pop();
        values.push(op.f(...[].concat(...values.splice(-op.argCount))));
        return op.precedence;
      },
      error = (msg) => {
        let notation = match ? match.index : expression.length;
        return `${msg} at ${notation}:\n${expression}\n${" ".repeat(
          notation
        )}^`;
      },
      pattern = new RegExp(
        // Pattern for numbers
        "\\d+(?:\\.\\d+)?|" +
          // ...and patterns for individual operators/function names
          Object.values(this._symbols)
            // longer symbols should be listed first
            .sort((a, b) => b.symbol.length - a.symbol.length)
            .map((val) => val.regSymbol)
            .join("|") +
          "|(\\S)",
        "g"
      );
    let afterValue = false;
    pattern.lastIndex = 0; // Reset regular expression object
    do {
      match = pattern.exec(expression);
      const [token, bad] = match || [")", undefined],
        notNumber = this._symbols[token],
        notNewValue = notNumber && !notNumber.prefix && !notNumber.func,
        notAfterValue = !notNumber || (!notNumber.postfix && !notNumber.infix);
      // Check for syntax errors:
      if (bad || (afterValue ? notAfterValue : notNewValue))
        return error("Syntax error");
      if (afterValue) {
        // We either have an infix or postfix operator (they should be mutually exclusive)
        const curr = notNumber.postfix || notNumber.infix;
        do {
          const prev = operators[operators.length - 1];
          if ((curr.precedence - prev.precedence || prev.rightToLeft) > 0)
            break;
          // Apply previous operator, since it has precedence over current one
        } while (exec()); // Exit loop after executing an opening parenthesis or function
        afterValue = curr.notation === "postfix";
        if (curr.symbol !== ")") {
          operators.push(curr);
          // Postfix always has precedence over any operator that follows after it
          if (afterValue) exec();
        }
      } else if (notNumber) {
        // prefix operator or function
        operators.push(notNumber.prefix || notNumber.func);
        if (notNumber.func) {
          // Require an opening parenthesis
          match = pattern.exec(expression);
          if (!match || match[0] !== "(")
            return error("Function needs parentheses");
        }
      } else {
        // number
        values.push(+token);
        afterValue = true;
      }
    } while (match && operators.length);
    return operators.length
      ? error("Missing closing parenthesis")
      : match
      ? error("Too many closing parentheses")
      : values.pop(); // All done!
  }
}

/**
 * Find the hide/show sections and fileds.
 * @param {form_element} element
 */
export const controlSettings = (element) => {
  let hideElements = [];
  let guidelines = {};
  element.control_settings.forEach((elm) => {
    let allow = false;
    if (
      elm.values === null ||
      (Array.isArray(elm.values) &&
        elm.values.length === 0 &&
        (!element.value ||
          element.value === "" ||
          (Array.isArray(element.value) && element.value.length === 0)))
    ) {
      allow = true;
    }

    if (!allow) {
      if (element.type === "checkbox" || element.type === "multiselect") {
        if (!element.value) element.value = [];
        Array.isArray(elm.values) &&
          elm.values.forEach((v) => {
            element.value &&
              element.value.forEach((e) => {
                let val = "";
                if (e.value) {
                  val = e.value;
                } else {
                  val = e;
                }
                if (checkActions(val, v.value, elm.as)) allow = true;
              });
          });
      } else {
        Array.isArray(elm.values) &&
          elm.values.forEach((v) => {
            if (element.value === undefined || element.value === null)
              element.value = "";

            if (checkActions(element.value, v.value, elm.as)) allow = true;
          });
      }
    }

    if (allow) {
      // Hide sections
      elm.hide_sections &&
        elm.hide_sections.forEach((s) => {
          hideElements = [...hideElements, s.value];
        });

      // Hide fields
      elm.hide_fields &&
        elm.hide_fields.forEach((f) => {
          hideElements = [...hideElements, f.value];
        });

      // show guidelines
      if (!!elm.guideline) {
        guidelines = { ...guidelines, [element.id]: elm.guideline };
      }
    }
  });
  return { hideElements, guidelines };
};

export const processControlSettings = (MI) => {
  let hideSectionsAndFields = [];
  let guidelines = {};
  const getHideSectionAndFields = (configs) => {
    configs.forEach((config) => {
      if (config.control_settings) {
        let obj = controlSettings(config);
        hideSectionsAndFields = [...hideSectionsAndFields, ...obj.hideElements];
        guidelines = { ...guidelines, ...obj.guidelines };
      }
      if (config.childs && config.childs.length) {
        getHideSectionAndFields(config.childs);
      }
    });
  };

  getHideSectionAndFields(MI.form.content);
  MI.form.hideSectionsAndFields = hideSectionsAndFields;
  MI.form.guidelines = guidelines;

  if (Array.isArray(hideSectionsAndFields)) {
    // hideSectionsAndFields = [
    //   ...hideSectionsAndFields,
    //   ...getHiddenSectionFields(hideSectionsAndFields, MI.form.content),
    // ];
    hideSectionsAndFields.forEach((f) => {
      if (MI.dynamicFieldData) {
        MI.dynamicFieldData[f] = "";
      }
      if (MI.data) {
        MI.data[f] = "";
      }
    });

    MI.form.content = removeElementFromForm(
      MI.form.content,
      MI.form.hideSectionsAndFields
    );
  }
  return MI;
};

// const getHiddenSectionFields = (elements, form) => {
//   let tfields = [];
//   form &&
//     form.forEach((config) => {
//       if (elements.includes(config.id) && config.childs) {
//         tfields = [...tfields, ...getHiddenFields(config.childs)];
//       }
//       if (config.childs) {
//         tfields = [
//           ...tfields,
//           ...getHiddenSectionFields(elements, config.childs),
//         ];
//       }
//     });
//   return tfields;
// };

// const getHiddenFields = (form) => {
//   let ttfields = [];
//   form &&
//     form.forEach((config) => {
//       if (!NonInputElements.includes(config.type)) {
//         ttfields = [...ttfields, config.id];
//       }
//       if (config.childs) {
//         ttfields = [...ttfields, ...getHiddenFields(config.childs)];
//       }
//     });
//   return ttfields;
//};

const clearHiddenSectionFieldValue = (configs) => {
  let elements = [];
  configs.forEach((c) => {
    c.value = "";

    if (c.childs && c.childs.length) {
      c.childs = clearHiddenSectionFieldValue(c.childs);
    }
    elements = [...elements, c];
  });
  return elements;
};

/**
 * Remove controled element from form
 * @param {form_data} content
 * @param {hide and show sections, fileds} hide_sections_and_fields
 */
export const removeElementFromForm = (content, hide_sections_and_fields) => {
  let bindedData = [];
  content.forEach((config) => {
    config.show = true;
    if (hide_sections_and_fields.includes(config.id)) {
      config.show = false;
      config.value = "";

      if (config.childs && config.childs.length) {
        config.childs = clearHiddenSectionFieldValue(config.childs);
      }
    } else {
      if (config.childs && config.childs.length) {
        config.childs = removeElementFromForm(
          config.childs,
          hide_sections_and_fields
        );
      }
    }
    bindedData = [...bindedData, config];
  });
  return bindedData;
};

/**
 *
 * @param {form} content
 * @param {id of the element} id
 * @param {db_value} value
 */
export const bindValuesInForm = (content, id, value) => {
  let bindedData = [];
  content &&
    content.forEach((config) => {
      if (value === "0" || value === "0.00") {
        value = "0";
      }
      if (id === config.id) {
        config.value = value;
      } else {
        config.childs = bindValuesInForm(config.childs, id, value);
      }
      bindedData = [...bindedData, config];
    });
  return bindedData;
};

/**
 *
 * @param {html element event} event
 * @param {id of the element} elmId
 * @param {module info} moduleInfo
 */
export const bindInputDataToState = (event, elmId, moduleInfo, directValue) => {
  let MI = { ...moduleInfo };
  let id = "";
  let value;

  if (!!elmId && !!directValue) {
    if (
      typeof directValue === "object" &&
      typeof directValue.getMonth === "function"
    ) {
      directValue = directValue.toLocaleString();
    }

    id = elmId;
    value = directValue;
  } else if (event === null || !event.target) {
    // multi select
    id = elmId;
    value = event === null ? [] : event;
  } else if (event.target.type === "radio") {
    id = event.target.name;
    value = event.target.value;
  } else if (event.target.type === "checkbox") {
    value = [];
    id = event.target.name;
    let inputElements = document.getElementsByName(id);
    for (let i = 0; i < inputElements.length; i++) {
      if (event.target.id == inputElements[i].id) {
        inputElements[i].checked = event.target.checked;
      }

      if (inputElements[i].checked && !value.includes(inputElements[i].id)) {
        value = [...value, inputElements[i].id];
      }
    }
  } else {
    id = event.target.id;
    value = event.target.value;
  }
  // Removing mandatory field
  if (MI.requiredFields.includes(id)) {
    let fields = [];
    MI.requiredFields.forEach((f) => {
      if (id !== f) fields = [...fields, f];
    });
    MI.requiredFields = fields;
  }

  MI.data[id] = value;
  if (MI.dynamicFieldData && MI.dynamicFieldData[id]) {
    delete MI.dynamicFieldData[id];
  }

  MI.form.content = bindValuesInForm(MI.form.content, id, value);

  // Control settings & Loan Guide lines
  if (!MI.load) MI = processControlSettings(MI);

  // Processing Calculations
  MI = applyCalculation(MI, id, []);

  // Control settings & Loan Guide lines
  if (!MI.load) MI = processControlSettings(MI);

  return MI;
};

export const checkValidation = (cls, info) => {
  let requiredFields = [];

  document.querySelectorAll("." + cls).forEach((elm) => {
    if (
      info &&
      info.MDID === "" &&
      elm.type === undefined &&
      elm.firstElementChild.type === "file"
    ) {
      var file = document.querySelectorAll(
        '[name="' + elm.firstElementChild.id + '"]'
      );
      if (file.length === 1) {
        requiredFields = [...requiredFields, elm.firstElementChild.id];
      }
    }
    if (
      elm.type === undefined &&
      (elm.firstElementChild.type === "radio" ||
        elm.firstElementChild.type === "checkbox")
    ) {
      var checked_elements = document.querySelectorAll(
        '[name="' + elm.firstElementChild.name + '"]'
      );

      let checkedCount = 0;
      checked_elements.forEach((e) => {
        if (e.checked) checkedCount++;
      });

      if (checkedCount === 0) {
        requiredFields = [...requiredFields, elm.firstElementChild.name];
      }
    } else {
      if (elm.value === "") {
        requiredFields = [...requiredFields, elm.id];
      }
    }
  });

  // Multi select fields.
  info &&
    info.mandatoryMultiselect.forEach((f) => {
      if (
        !(info.data[f] || (info.dynamicFieldData && info.dynamicFieldData[f]))
      )
        requiredFields = [...requiredFields, f];
    });

  // Auth validations
  if (
    document.getElementById("allow_to_login") != null &&
    document.getElementById("allow_to_login").checked
  ) {
    if (document.getElementById("username").value === "") {
      requiredFields = [...requiredFields, "username"];
    }

    if (document.getElementById("password") != null) {
      if (document.getElementById("password").value === "") {
        requiredFields = [...requiredFields, "password"];
      } else if (
        document.getElementById("password").value !==
        document.getElementById("confirm_password").value
      ) {
        requiredFields = [...requiredFields, "confirm_password"];
      }
    }
  }

  return requiredFields;
};

const getDuplicateCnt = (arr, val) => {
  let cnt = 0;
  arr &&
    arr.forEach((a) => {
      if (val === a) cnt++;
    });
  return cnt;
};

export const applyCalculation = (moduleInfo, elmId) => {
  let MI = { ...moduleInfo };

  const checkAndGetCalculation = (configs, elmId) => {
    configs.forEach((config) => {
      if (config.calculations) {
        config.calculations.forEach((cal) => {
          // Check element in coditions
          let exists = false;
          cal.conditions.forEach((c) => {
            if (
              c.field === elmId ||
              (c.value_type === "field" && elmId === c.value)
            ) {
              exists = true;
            }
          });

          if (exists && getDuplicateCnt(MI.calculated_fields, config.id) <= 1) {
            if (checkCondition(MI, cal.conditions)) {
              MI = processFormulas(MI, cal.formulas, config.id);
            }
          }

          // Check element in formulas
          exists = false;
          cal.formulas.forEach((f) => {
            if (f.field === elmId) {
              exists = true;
            }
          });

          if (exists && getDuplicateCnt(MI.calculated_fields, config.id) <= 1) {
            if (checkCondition(MI, cal.conditions, elmId)) {
              MI = processFormulas(MI, cal.formulas, config.id);
            }
          }
        });
      }

      if (config.childs && config.childs.length && !config.cloneable)
        checkAndGetCalculation(config.childs, elmId);
    });
  };

  checkAndGetCalculation(MI.form.content, elmId);

  return MI;
};

const checkCondition = (MI, conditions, otp) => {
  let trueCnt = 0;
  conditions.forEach((con) => {
    if (Array.isArray(con.value)) {
      if (MI.data && MI.data[con.field]) {
        if (Array.isArray(MI.data[con.field])) {
          con.value.forEach((co) => {
            MI.data[con.field].forEach((d) => {
              if (co.id === d.id) trueCnt++;
            });
          });
        } else {
          con.value.forEach((co) => {
            if (co.id === MI.data[con.field] || co.value === MI.data[con.field])
              trueCnt++;
          });
        }
      } else if (MI.dynamicFieldData && MI.dynamicFieldData[con.field]) {
        if (Array.isArray(MI.dynamicFieldData[con.field])) {
          con.value.forEach((co) => {
            MI.dynamicFieldData[con.field].forEach((d) => {
              if (co.id === d.id) trueCnt++;
            });
          });
        } else {
          con.value.forEach((co) => {
            if (co.id === MI.dynamicFieldData[con.field]) trueCnt++;
          });
        }
      }
    } else {
      let a = getValue(MI, con.field);
      let b = con.value;
      if (con.value_type === "field") b = getValue(MI, con.value);

      if (checkActions(a, b, con.as)) trueCnt++;
    }
  });

  if (trueCnt === conditions.length) {
    return true;
  } else {
    return false;
  }
};

const getValue = (MI, id) => {
  if (MI.data && MI.data[id]) {
    return MI.data[id];
  } else if (MI.dynamicFieldData && MI.dynamicFieldData[id]) {
    return MI.dynamicFieldData[id];
  } else {
    return 0;
  }
};

const checkActions = (a, b, as) => {
  /* eslint eqeqeq: 0 */
  if (as === "=" && a == b) {
    return true;
  } else if (as === ">" && getNumericValue(a) > getNumericValue(b)) {
    return true;
  } else if (as === "<" && getNumericValue(a) < getNumericValue(b)) {
    return true;
  } else if (as === ">=" && getNumericValue(a) >= getNumericValue(b)) {
    return true;
  } else if (as === "<=" && getNumericValue(a) <= getNumericValue(b)) {
    return true;
  } else {
    return false;
  }
};

const getNumericValue = (value) => {
  let result = 0;
  if (!!value) {
    result = value.toString();
    result = result.replaceAll(",", "").replace(" ", "");
    result = parseFloat(result);
  }
  return result;
};

const convertExpToInt = (n) => {
  let decimal = n.toString().toLowerCase();
  if (!decimal.includes("e+") && decimal.includes("e-")) {
    return n;
  }
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
        (exponentialSplitted[0].includes(".")
          ? exponentialSplitted[0].split(".")[1].length
          : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

Calculation = new Calculation();
const processFormulas = (MI, formulas, elmId) => {
  let formula = "";
  formulas.forEach((fo, i) => {
    if (fo.field === "input") {
      formula += convertExpToInt(getNumericValue(fo.value));
    } else if (MI.data && MI.data.hasOwnProperty(fo.field)) {
      formula += convertExpToInt(getNumericValue(MI.data[fo.field]));
    } else if (MI.dynamicFieldData && MI.dynamicFieldData[fo.field]) {
      formula += convertExpToInt(
        getNumericValue(MI.dynamicFieldData[fo.field])
      );
    } else if (["(", ")", "-", "+", "x", "/", "^"].includes(fo.field)) {
      if (fo.field === "x") {
        formula += "*";
      } else {
        formula += fo.field;
      }
    } else {
      formula += 0;
    }
  });

  let result = Calculation.calculate(formula);
  MI.data = { ...MI.data, [elmId]: result };
  MI.form.content = bindValuesInForm(
    MI.form.content,
    elmId,
    parseFloat(result).toFixed(2)
  );

  if (MI.calculated_fields) {
    MI.calculated_fields = [...MI.calculated_fields, elmId];
  } else {
    MI.calculated_fields = [elmId];
  }

  if (!MI.loop_block) MI = applyCalculation(MI, elmId);
  MI.calculated_fields = [];

  return MI;
};

export const checkCloneableSectionTotal = (moduleInfo) => {
  let MI = { ...moduleInfo };
  let fields = [];

  const getTotalFields = (configs) => {
    configs.forEach((c) => {
      if (!!c.total_of) {
        fields = [...fields, c];
      }
      if (c.childs && c.childs.length && !c.cloneable) getTotalFields(c.childs);
    });
  };
  getTotalFields(MI.form.content);

  const bindValueToForm = (configs, data) => {
    let bindedConfig = [];
    configs.forEach((c) => {
      if (data[c.id]) c.value = data[c.id];
      if (c.childs && c.childs.length > 0) bindValueToForm(c.childs, data);
      bindedConfig = [...bindedConfig, c];
    });

    return bindedConfig;
  };

  if (fields.length > 0) {
    fields.forEach((f) => {
      for (var key in MI.data) {
        if (key.includes("section")) {
          let total = 0;
          MI.data[key] &&
            MI.data[key].forEach((dd) => {
              if (dd[f.total_of]) {
                total += getNumericValue(dd[f.total_of]);
              }
            });

          if (total > 0) {
            MI.data[f.id] = total;
            MI = bindInputDataToState({}, f.id, MI, total);
            return true;
          }
        }
      }
    });
  }

  MI.form.content = bindValueToForm(MI.form.content, MI.data);
  return MI;
};

export const getElementsFromForm = (form, info) => {
  let elements = [];
  Array.isArray(form) &&
    form.forEach((f) => {
      if (info.types && info.types.includes(f.type)) {
        elements = [...elements, f];
      }
      if (Array.isArray(f.childs)) {
        elements = [...elements, ...getElementsFromForm(f.childs, info)];
      }
    });
  return elements;
};

export const getFileExtension = (filename) => {
  var ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? "" : ext[1];
};

export const addLabelForSelect = (data, idField, valFields, isSubdoc) => {
  let result = [];
  Array.isArray(data) &&
    data.forEach((d) => {
      if (idField === "_id") d.value = d._id;

      d.label = "";
      if (isSubdoc === true) {
        Object.keys(d.info).forEach(function(k) {
          if (valFields.includes(k)) {
            d.label += !!d.label ? " | " + d.info[k] : d.info[k];
          }
        });
      } else {
        Object.keys(d).forEach(function(k) {
          if (valFields.includes(k)) {
            d.label += !!d.label ? " | " + d[k] : d[k];
          }
        });
      }

      result = [...result, d];
    });
  return result;
};

export const tabValidation = (cls, info) => {
  let requiredFields = [];

  document.querySelectorAll("." + cls).forEach((elm) => {
    var style = elm.offsetLeft;
    if (style > 0) {
      if (
        elm.type === undefined &&
        (elm.firstElementChild.type === "radio" ||
          elm.firstElementChild.type === "checkbox")
      ) {
        var checked_elements = document.querySelectorAll(
          '[name="' + elm.firstElementChild.name + '"]'
        );

        let checkedCount = 0;
        checked_elements.forEach((e) => {
          if (e.checked) checkedCount++;
        });

        if (checkedCount === 0) {
          requiredFields = [...requiredFields, elm.firstElementChild.name];
        }
      } else {
        if (elm.value === "") {
          requiredFields = [...requiredFields, elm.id];
        }
      }
    }
  });

  // Multi select fields.
  info &&
    info.mandatoryMultiselect.forEach((f) => {
      if (
        !(info.data[f] || (info.dynamicFieldData && info.dynamicFieldData[f]))
      )
        requiredFields = [...requiredFields, f];
    });

  // Auth validations
  if (
    document.getElementById("allow_to_login") != null &&
    document.getElementById("allow_to_login").checked
  ) {
    if (document.getElementById("username").value === "") {
      requiredFields = [...requiredFields, "username"];
    }

    if (document.getElementById("password") != null) {
      if (document.getElementById("password").value === "") {
        requiredFields = [...requiredFields, "password"];
      } else if (
        document.getElementById("password").value !==
        document.getElementById("confirm_password").value
      ) {
        requiredFields = [...requiredFields, "confirm_password"];
      }
    }
  }

  return requiredFields;
};

export const processCalculation = (moduleInfo, config) => {
  let MI = { ...moduleInfo };
  MI.loop_block = true;
  config.calculations &&
    config.calculations.forEach((cal) => {
      // Check element in coditions
      cal.conditions.forEach((c) => {
        if (
          getDuplicateCnt(MI.calculated_fields, config.id) === 0 &&
          checkCondition(MI, cal.conditions)
        ) {
          MI = processFormulas(MI, cal.formulas, config.id);
        }
      });

      // Check element in formulas
      cal.formulas.forEach((f) => {
        if (
          getDuplicateCnt(MI.calculated_fields, config.id) === 0 &&
          checkCondition(MI, cal.conditions)
        ) {
          MI = processFormulas(MI, cal.formulas, config.id);
        }
      });
    });

  MI.loop_block = false;
  return MI;
};

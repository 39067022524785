import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { OverlayTrigger, Popover, Nav, Tab } from "react-bootstrap";
import { SketchPicker } from "react-color";

import { backendApiUrl, getObjectVal } from "../../utils";
import "./waves.css";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const QuickPanel = (props) => {
  const [state, setState] = useState({
    layout: getObjectVal(
      props.menuConfig.personalization,
      "layout",
      "Horizontal"
    ),
    mode: getObjectVal(props.menuConfig.personalization, "mode", "Light"),
    menuColor: getObjectVal(
      props.menuConfig.personalization,
      "menuColor",
      "#061F2B"
    ),
    sectionHeaderColor: getObjectVal(
      props.menuConfig.personalization,
      "sectionHeaderColor",
      "#49839f"
    ),
  });

  const handleChange = (e, field) => {
    let val = "";
    let st = { ...state };

    if (e.hex) {
      val = e.hex;
    } else if (e.target.type === "radio") {
      val = e.target.value;
    }

    st[field] = val;
    setState(st);
  };

  const resetHandler = () => {
    setState({
      layout: "Horizontal",
      mode: "Light",
      menuColor: "#061F2B",
      sectionHeaderColor: "#49839f",
    });
  };

  const saveHandler = async () => {
    await axios
      .post(backendApiUrl("module/personalization"), state, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  return (
    <div id="kt_quick_panel" className="kt-quick-panel">
      <span className="kt-quick-panel__close" id="kt_quick_panel_close_btn">
        <i className="flaticon2-delete" />
      </span>
      <Tab.Container defaultActiveKey="Settings">
        <div className="kt-quick-panel__nav">
          <Nav className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x">
            <Nav.Item>
              <h5 className="mt-4">Theme Customizer</h5>
            </Nav.Item>
          </Nav>
        </div>
        <div className="kt-quick-panel__content">
          <Tab.Content>
            <Tab.Pane eventKey="Settings">
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  position: "relative",
                }}
              >
                <div className="kt-quick-panel__content-padding-x">
                  <div className="row">
                    <div className="col-12">
                      <h6>Choose layout</h6>
                    </div>

                    <div className="col-4" align="center">
                      <div className="form-check card-radio">
                        <input
                          id="hLayout"
                          name="layout"
                          type="radio"
                          className="form-check-input"
                          value="Horizontal"
                          checked={state.layout === "Horizontal" ? true : false}
                          onChange={(e) => handleChange(e, "layout")}
                        />
                        <label
                          className="form-check-label p-0 avatar-md w-100"
                          htmlFor="hLayout"
                        >
                          <span className="d-flex h-100 flex-column gap-1">
                            <span className="bg-grey d-block p-1">
                              <span className="d-block p-1 px-2 bg-primary-subtle rounded"></span>
                            </span>
                            <span className="d-block p-1 mt-auto"></span>
                          </span>
                        </label>
                      </div>
                      <p>Horizontal</p>
                    </div>
                    <div className="col-4" align="center">
                      <div className="form-check card-radio">
                        <input
                          id="vLayout"
                          name="layout"
                          type="radio"
                          className="form-check-input"
                          value="Vertical"
                          checked={state.layout === "Vertical" ? true : false}
                          onChange={(e) => handleChange(e, "layout")}
                        />
                        <label
                          className="form-check-label p-0 avatar-md w-100"
                          htmlFor="vLayout"
                        >
                          <span className="d-flex gap-1 h-100">
                            <span className="flex-shrink-0">
                              <span className="bg-grey d-flex h-100 flex-column gap-1 p-1">
                                <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              </span>
                            </span>
                            <span className="flex-grow-1">
                              <span className="d-flex h-100 flex-column">
                                <span className="bg-grey d-block p-1">
                                  <span className="d-block p-1 px-2 bg-primary-subtle rounded"></span>
                                </span>
                                <span className="d-block p-1 mt-auto"></span>
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                      <p>Vertical</p>
                    </div>

                    <div className="col-12 mt-3">
                      <h6>Choose Mode</h6>
                    </div>
                    <div className="col-4" align="center">
                      <div className="form-check card-radio">
                        <input
                          id="lMode"
                          name="mode"
                          type="radio"
                          className="form-check-input"
                          value="Light"
                          onChange={(e) => handleChange(e, "mode")}
                          checked={state.mode === "Light" ? true : false}
                        />
                        <label
                          className="form-check-label p-0 avatar-md w-100"
                          htmlFor="lMode"
                        >
                          <span className="d-flex gap-1 h-100">
                            <span className="flex-shrink-0">
                              <span className="bg-grey d-flex h-100 flex-column gap-1 p-1">
                                <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              </span>
                            </span>
                            <span className="flex-grow-1">
                              <span className="d-flex h-100 flex-column">
                                <span className="bg-grey d-block p-1">
                                  <span className="d-block p-1 px-2 bg-primary-subtle rounded"></span>
                                </span>
                                <span className="d-block p-1 mt-auto"></span>
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                      <p>Light</p>
                    </div>
                    <div className="col-4" align="center">
                      <div className="form-check card-radio">
                        <input
                          id="dMode"
                          name="mode"
                          type="radio"
                          className="form-check-input"
                          value="Dark"
                          onChange={(e) => handleChange(e, "mode")}
                          checked={state.mode === "Dark" ? true : false}
                        />
                        <label
                          className="form-check-label p-0 avatar-md w-100"
                          htmlFor="dMode"
                        >
                          <span className="d-flex gap-1 h-100  bg-black">
                            <span className="flex-shrink-0">
                              <span className="bg-black d-flex h-100 flex-column gap-1 p-1">
                                <span className="d-block p-1 px-2 bg-grey rounded mb-2"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-grey"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-grey"></span>
                                <span className="d-block p-1 px-2 pb-0 bg-grey"></span>
                              </span>
                            </span>
                            <span className="flex-grow-1">
                              <span className="d-flex h-100 flex-column">
                                <span className="bg-black d-block p-1">
                                  <span className="d-block p-1 px-2 bg-grey rounded"></span>
                                </span>
                                <span className="d-block p-1 mt-auto"></span>
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                      <p>Dark</p>
                    </div>

                    <div className="col-12">
                      <h6>Choose Menu Color</h6>
                      <OverlayTrigger
                        placement="bottom"
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover>
                            <SketchPicker
                              color={state.menuColor}
                              onChange={(e) => handleChange(e, "menuColor")}
                            />
                          </Popover>
                        }
                      >
                        <div
                          style={{
                            marginLeft: "20px",
                            padding: "5px",
                            background: "#fff",
                            borderRadius: "1px",
                            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "220px",
                              height: "30px",
                              borderRadius: "2px",
                              background: state.menuColor,
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>

                    <div className="col-12">
                      <h6>Choose Section Header Color</h6>
                      <OverlayTrigger
                        placement="bottom"
                        trigger="click"
                        rootClose
                        overlay={
                          <Popover>
                            <SketchPicker
                              color={state.sectionHeaderColor}
                              onChange={(e) =>
                                handleChange(e, "sectionHeaderColor")
                              }
                            />
                          </Popover>
                        }
                      >
                        <div
                          style={{
                            marginLeft: "20px",
                            padding: "5px",
                            background: "#fff",
                            borderRadius: "1px",
                            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                            display: "inline-block",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              width: "220px",
                              height: "30px",
                              borderRadius: "2px",
                              background: state.sectionHeaderColor,
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </PerfectScrollbar>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "0px",
          right: "0px",
          marginBottom: "0px",
          padding: "10px",
          borderTop: "2px solid #ebedf2",
        }}
        align="right"
      >
        <button className="btn btn-sm btn-default mr-2" onClick={resetHandler}>
          Reset
        </button>
        <button className="btn btn-sm btn-primary" onClick={saveHandler}>
          Update Changes
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ builder }) => ({
  menuConfig: builder.menuConfig,
});

export default connect(mapStateToProps)(QuickPanel);

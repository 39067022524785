import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { backendApiUrl } from "../utils";

const StatusUpdate = (props) => {
  const [edit, setEdit] = useState(false);
  let ts = [];
  if (props.data.status) {
    props.data.status.forEach((s) => {
      ts = [...ts, { label: s.title, value: s._id }];
    });
  }
  const [status, setStatus] = useState(ts);

  const statusSelectHandler = (tempStatus) => {
    tempStatus = [tempStatus]; // As per Sam's request, I have updated the multi-select to a single select on October 12, 2023.

    setStatus(tempStatus);
    if (tempStatus === null) tempStatus = [];

    let data = { status: tempStatus };

    axios
      .patch(
        backendApiUrl("module/" + props.form + "-" + props.data._id),
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        props.setMsg({
          status: 200,
          msg: "Status has been updated successfully..",
          show: true,
        });
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg: "Problem while updating status, please try again.",
          show: true,
        });
      });
  };

  return (
    <div>
      <i
        className="fa fa-edit fa-lg text-primary mr-2"
        onClick={() => setEdit(edit ? false : true)}
        title="Click to update status"
      />
      {edit ? (
        <Select
          className="kt-width-full"
          name="status"
          options={props.status}
          onChange={statusSelectHandler}
          value={status}
          isDisabled={props.data.readonly ? true : false}
          placeholder="Please select"
        />
      ) : (
        <span>
          {status.map((s, i) => (
            <span key={i}>
              {s.label}
              <br />
            </span>
          ))}
        </span>
      )}
    </div>
  );
};

export default StatusUpdate;

import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";

export function ErrorPage1() {
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1">
          <div className="kt-error-v1__container">
            <h1 className="kt-error-v1__number">403</h1>
            <p className="kt-error-v1__desc text-danger">
              OOPS! Permission is disabled for the this module, please enable
              the permission to view the page.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { FormattedMessage } from "react-intl";

const MenuItemText = ({ item, onClick }) => {
  let inputProps = {};
  if (item.type) inputProps = { onClick };

  return (
    <>
      {item.icon ? (
        <i className={`kt-menu__link-icon ${item.icon}`} />
      ) : (
        <i className="kt-menu__link-icon fa fa-user" />
      )}

      <span className="kt-menu__link-text" {...inputProps}>
        {!item.translate ? (
          item.custom_title || item.title
        ) : (
          <FormattedMessage
            id={item.translate}
            defaultMessage={item.custom_title || item.title}
          />
        )}
      </span>

      {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
    </>
  );
};
export default MenuItemText;

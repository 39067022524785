/**
 * Date : 10-06-2023
 * Task : Market Place
 * Company: FinLend, LLC
 * Requested By : Rajesh
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { backendApiUrl, getKeyValue } from "../../utils";

const MarketPlace = ({ info }) => {
  const [state, setState] = useState({
    load: true,
    records: [],
  });

  useEffect(() => {
    if (state.load) {
      axios
        .post(
          backendApiUrl("opportunities/lenders"),
          { loan: info.MDID },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let st = { ...state, load: false };
          if (res.data) st.records = res.data;
          setState(st);
          console.log(res.data);
        });
    }
  }, [state, info]);

  return (
    <Portlet>
      <PortletHeader
        title="Lenders"
        toolbar={<PortletHeaderToolbar></PortletHeaderToolbar>}
      />
      <PortletBody>
        {state.load ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true">Lender Company</th>
                  <th nowrap="true">Property Type</th>
                  <th nowrap="true">Loan Programs</th>
                  <th nowrap="true">Min Loan Amount</th>
                  <th nowrap="true">Max Loan Amount</th>
                  <th nowrap="true">State We Lend In</th>
                  <th nowrap="true">Min Credit Score Range</th>
                  <th nowrap="true">Max LTV</th>
                  <th nowrap="true">Min. Down Payment Required</th>
                </tr>
              </thead>
              <tbody>
                {state.records.length > 0 ? (
                  state.records.map((r, i) => (
                    <tr>
                      <th>{i + 1}</th>
                      <td>
                        <Link to={"/contacts/edit/" + r._id}>
                          {getKeyValue(r.info, "first_name")}
                        </Link>
                      </td>
                      <td>
                        {getKeyValue(r.info, "property_types") &&
                          getKeyValue(r.info, "property_types").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>
                        {getKeyValue(r.info, "loan_programs") &&
                          getKeyValue(r.info, "loan_programs").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>{getKeyValue(r.info, "min_loan_amount")}</td>
                      <td>{getKeyValue(r.info, "max_loan_amount")}</td>
                      <td>
                        {getKeyValue(r.info, "state_we_lend_in") &&
                          getKeyValue(r.info, "state_we_lend_in").map((pt) => (
                            <span>
                              {pt.label}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td>{getKeyValue(r.info, "min_credit_score_range")}</td>
                      <td>{getKeyValue(r.info, "max_ltv")}</td>
                      <td>{getKeyValue(r.info, "min_down_payment")}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h5 className="text-primary text-center">
                        No lenders available!
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </PortletBody>
    </Portlet>
  );
};

export default MarketPlace;

import React from "react";
import FormGroup from "./utils/FormGroup";

const Select = (props) => {
  let value = "";
  props.element.options &&
    props.element.options.forEach((element) => {
      if (
        props.element.value === element.text ||
        props.element.value === element.id
      ) {
        value = element.id;
      }
    });
  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <select
          id={props.element.id}
          className={"form-control " + props.element.class}
          onChange={props.events.change}
          disabled={props.element.readonly ? true : false}
          value={value}
        >
          <option value="" disabled>
            Please select
          </option>
          {props.element.options ? (
            props.element.options.map((option, i) => {
              return (
                <option value={option.id} key={i}>
                  {option.text}
                </option>
              );
            })
          ) : (
            <option value="option1">Option 1</option>
          )}
        </select>
      ) : (
        <h6 className="text-primary">
          {props.element?.options?.find((o) => props.element.value === o.id)
            ?.text || ""}
        </h6>
      )}
    </FormGroup>
  );
};

export default Select;

import React from "react";
import { connect } from "react-redux";
import * as builder from "../../../_metronic/ducks/builder";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../../_metronic/_assets/js/offcanvas";
import Brand from "../brand/Brand";

import UserProfile from "../../../app/partials/layout/UserProfile";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  render() {
    return (
      <>
        <div
          id="kt_aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <Brand />
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            <PerfectScrollbar
              options={{ wheelSpeed: 2, wheelPropagation: false }}
              style={{
                height: window.innerHeight - 110 + "px",
                position: "fixed",
              }}
            >
              <Menu htmlClassService={this.props.htmlClassService} />

              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                }}
                align="right"
              >
                <div
                  className="kt-header__topbar"
                  style={{
                    padding: "10px 0 10px 0",
                  }}
                >
                  <UserProfile
                    showAvatar={true}
                    showHi={false}
                    showBadge={false}
                  />
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active",
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);

import React, { useState, useEffect } from "react";
// import { Table } from "react-bootstrap";

import { Grid } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";
import {
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal,
  Badge,
  // Popover,
  Button,
} from "react-bootstrap";
import { CiMenuKebab } from "react-icons/ci";
import { LiaFileSignatureSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
// import { FaChevronDown } from "react-icons/fa";

import "../../_metronic/_assets/sass/app_tab.scss";
import { backendApiUrl } from "../utils";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../partials/content/Portlet";
import ListSettings from "../utils/ProspectListSettings";
import JumbLink from "../utils/JumbLink";
import StatusUpdate from "../module/StatusUpdate";
import { getDate } from "../utils";
import ProspectStatus from "./ProspectStatus";
import AssignedProspect from "./AssignedProspect";
import Message from "../utils/Message";

const Admin = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [signed, setDocstatus] = useState("unsigned");
  const [msg, setMsg] = useState({ show: false });

  let MI = { ...props.module };
  let info = MI.admin ? MI.admin : {};

  const [state, setState] = useState({
    load: true,
    products: [],
    programs: [],
    allPrograms: [],
    workforces: [],
    contacts: [],
    prospects: [],
    fields: [],
    showColumns: [],
    productWithPrograms: [],
  });

  const onSelectHandler = (values, field) => {
    if (values === null) values = [];

    if (field === "status") values = [values]; // As per Sam's request, I have updated the multi-select to a single select on October 12, 2023.

    MI.admin[field] = values;

    let st = { ...state };
    if (field === "product") {
      st.programs = [];
      state.allPrograms.forEach((p) => {
        if (values._id === p.product) {
          p.value = p._id;
          p.label = p.title;
          st.programs = [...st.programs, p];
        }
      });
    }
    setState(st);
    props.actions.setModuleInfo(MI);
  };

  const saveListSettings = async (columns) => {
    const bindConfig = (configs) => {
      let bindedConfig = [];
      configs.forEach((config, i) => {
        let tempConfig = { ...config };
        if (
          ![
            "container",
            "grid",
            "item",
            "tab",
            "section",
            "file",
            "paragraph",
            "separator",
            "headertext",
          ].includes(config.type)
        ) {
          tempConfig.order = 10000;
          tempConfig.showListView = false;
          columns.forEach((field) => {
            if (tempConfig.id === field.id) {
              tempConfig.order = parseInt(field.order);
              tempConfig.showListView = true;
            }
          });
        } else {
          if (Array.isArray(config.childs))
            tempConfig.childs = bindConfig(config.childs);
        }
        bindedConfig = [...bindedConfig, tempConfig];
      });
      return bindedConfig;
    };
    let form = { ...state.form };
    form.content = bindConfig(form.content);
    await axios
      .patch(backendApiUrl("prospects/forms/" + state.form._id), form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let st = { ...state };
        st.load = true;
        setState(st);
      });
  };

  const handleLoadUpdate = (newLoadValue) => {
    let st = { ...state };
    st.load = newLoadValue;
    setState(st);
  };

  const setActionMsg = (msg) => {
    MI.msg = msg;
    setMsg(msg);
  };

  useEffect(() => {
    if (state.load) {
      axios
        .get(backendApiUrl("opportunities/admin/" + MI.opportunity._id))
        .then((res) => {
          let rs = { ...res.data };
          let st = { ...state };
          let opp = MI.opportunity ? MI.opportunity : {};
          MI.admin = {};
          st.products = rs.products;
          st.allPrograms = rs.programs;
          st.workforces = rs.workforces;
          st.contacts = rs.contacts;
          st.status = rs.status;
          st.source = rs.source;
          st.prospects = rs.prospects;
          st.groups = rs.groups;
          st.productWithPrograms = rs.productWithPrograms;
          st.fields = rs.fields;
          st.form = rs.form;
          st.prospectStatus = rs.prospectStatus;

          MI.assigned_prospects = [];
          if (rs.assignedprospects) {
            MI.assigned_prospects = rs.prospects.filter((pro) =>
              rs.assignedprospects.includes(pro._id.toString())
            );
          }

          if (rs.assignedProspectStatus) {
            const statusMapping = {};
            rs.prospectStatus.forEach((option) => {
              statusMapping[option.value] = option.label;
            });
            // Map status values to labels
            MI.eachProspectStatus = rs.assignedProspectStatus.map(
              (prospect) => ({
                prospectId: prospect.prospectId,
                statusLabel: statusMapping[prospect.status] || "New", // Provide a default label if status is not found
                _id: prospect._id,
              })
            );
          }

          if (!!opp.product) {
            rs.products.forEach((p) => {
              if (opp.product === p._id) MI.admin.product = p;
            });
            rs.programs.forEach((p) => {
              if (opp.product === p.product) st.programs = [...st.programs, p];
              if (!!opp.program && opp.program._id === p._id) {
                MI.admin.program = {
                  label: p.title,
                  value: p.product + "---" + p._id,
                };
              }
            });
          }

          if (opp.workforces) {
            rs.workforces.forEach((w) => {
              opp.workforces &&
                opp.workforces.forEach((ow) => {
                  if (w.value === ow.user + "---" + ow.role) {
                    if (MI.admin.workforces) {
                      MI.admin.workforces = [...MI.admin.workforces, w];
                    } else {
                      MI.admin.workforces = [w];
                    }
                  }
                });
            });
          }

          if (opp.contacts) {
            rs.contacts.forEach((w) => {
              opp.contacts &&
                opp.contacts.forEach((ow) => {
                  if (w.value === ow.user + "---" + ow.role) {
                    if (MI.admin.contacts) {
                      MI.admin.contacts = [...MI.admin.contacts, w];
                    } else {
                      MI.admin.contacts = [w];
                    }
                  }
                });
            });
          }

          if (opp.status) {
            rs.status.forEach((w) => {
              if (opp.status.includes(w._id)) {
                if (MI.admin.status) {
                  MI.admin.status = [...MI.admin.status, w];
                } else {
                  MI.admin.status = [w];
                }
              }
            });
          }

          if (opp.source) {
            rs.source.forEach((w) => {
              if (opp.source.includes(w._id)) {
                if (MI.admin.source) {
                  MI.admin.source = [...MI.admin.source, w];
                } else {
                  MI.admin.source = [w];
                }
              }
            });
          }

          if (opp.groups) MI.admin.groups = opp.groups;

          st.load = false;
          setState(st);
          props.actions.setModuleInfo(MI);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state, MI, props.actions]);

  let allowToEdit = false;
  let permissions = {};
  if (props.user.role.admin || MI.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (MI.permissions && MI.permissions.auth) {
    permissions = MI.permissions.auth;
  }
  if (permissions.add || permissions.update) allowToEdit = true;

  const deleteProspect = (id) => {
    MI.assigned_prospects = MI.assigned_prospects.filter(
      (ap) => id !== ap.value
    );
    props.actions.setModuleInfo(MI);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      setSelectedDocuments([...selectedDocuments, id]);
    } else {
      const ids = selectedDocuments?.filter((f) => f !== id).map((f) => f);
      setSelectedDocuments(ids);
    }
  };

  return state.load ? (
    <div style={{ textAlign: "center" }}>
      <Spinner animation="border" variant="primary" />
    </div>
  ) : (
    <Grid container spacing={2}>
      <Grid
        item
        xs={8}
        style={{ maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}
      >
        <Portlet>
          <PortletHeader
            title="Assigned Prospects"
            toolbar={
              <PortletHeaderToolbar>
                <button
                  className="btn btn-sm btn-primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => setOpenPopup(true)}
                >
                  Add Prospect
                </button>
                <ListSettings
                  title="List Settings"
                  save={saveListSettings}
                  fields={state.fields}
                  menuConfig={props.user}
                />
              </PortletHeaderToolbar>
            }
          />
        </Portlet>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                {state.fields.map(
                  (column, i) =>
                    column.showListView && (
                      <th key={i} nowrap="true">
                        {column.label}
                      </th>
                    )
                )}
                <th nowrap="true">Actions</th>
              </tr>
            </thead>
            <tbody>
              {MI.assigned_prospects &&
                MI.assigned_prospects.map((d, i) => (
                  <tr key={i}>
                    <td>{1 + i}</td>
                    {state.fields.map(
                      (column, i) =>
                        column.showListView && (
                          <td key={i} nowrap="true">
                            <JumbLink
                              position={1}
                              url={"/prospects/edit/" + d.value}
                            />
                            {column.id === "first_name" ? (
                              <Link to={"/prospects/edit/" + d._id}>
                                {d.info && d.info[column.id]
                                  ? d.info[column.id]
                                  : ""}{" "}
                              </Link>
                            ) : column.id === "status" && d.status ? (
                              <StatusUpdate
                                data={d}
                                // status={Status}
                                // setMsg={setMsg}
                                // form={formInfo._id}
                              />
                            ) : d.info ? (
                              column.options ? (
                                Array.isArray(d.info[column.id]) ? (
                                  column.type === "checkbox" ? (
                                    column.options.map((option, i) => {
                                      return (
                                        d.info[column.id].includes(
                                          option.id
                                        ) && (
                                          <span key={i}>
                                            {option.text}
                                            <br />
                                          </span>
                                        )
                                      );
                                    })
                                  ) : (
                                    d.info[column.id].map((val, i) => (
                                      <span key={i}>
                                        {val.text}
                                        <br />
                                      </span>
                                    ))
                                  )
                                ) : (
                                  column.options.map(
                                    (option, i) =>
                                      d.info[column.id] === option.id && (
                                        <span key={i}>
                                          {option.text}
                                          <br />
                                        </span>
                                      )
                                  )
                                )
                              ) : column.type === "date" ? (
                                getDate(d.info[column.id])
                              ) : (
                                d.info[column.id]
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        )
                    )}
                    <td nowrap="true">
                      <ProspectStatus
                        status={state.prospectStatus}
                        opportunityId={MI.opportunity._id}
                        prospectId={d._id}
                        eachProspectStatus={MI.eachProspectStatus}
                      />
                      <LiaFileSignatureSolid
                        size={20}
                        className="mr-2"
                        style={{ verticalAlign: "top" }}
                        onClick={openModal}
                      />

                      <OverlayTrigger
                        placement="top"
                        trigger="click"
                        rootClose
                        overlay={
                          <Tooltip>
                            <ul className="kt-menu__nav">
                              <li className="kt-menu__section">
                                <Link to={`/prospects/edit/${d.value}`}>
                                  <i
                                    className="fa fa-pencil-alt fa-lg text-success mr-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </Link>
                              </li>
                              <li
                                className="kt-menu__section"
                                onClick={() => deleteProspect(d.value)}
                              >
                                <i
                                  className="fa fa-trash fa-lg mr-2 text-danger"
                                  aria-hidden="true"
                                />
                                Delete
                              </li>
                            </ul>
                          </Tooltip>
                        }
                      >
                        <CiMenuKebab
                          size={10}
                          style={{ verticalAlign: "top" }}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Grid>
      <Grid item xs={4}>
        {Array.isArray(info.workforces) && (
          <Portlet>
            <PortletHeader title="Assigned Workforces" />
            <PortletBody>
              <Grid container spacing={2}>
                {info.workforces.map((w, i) => (
                  <Grid item xs={6} key={i}>
                    <h5 className="text-primary">{w.role}</h5>
                    <p>First Name: {w.info.first_name}</p>
                    <p>Last Name: {w.info.last_name}</p>
                    <p>Email: {w.info.email}</p>
                    <p>Phone: {w.info.phone}</p>
                  </Grid>
                ))}
              </Grid>
            </PortletBody>
          </Portlet>
        )}
        <br />
        {Array.isArray(info.contacts) && (
          <Portlet>
            <PortletHeader title="Assigned Contacts" />
            <PortletBody>
              <Grid container spacing={2}>
                {info.contacts.map((w, i) => (
                  <Grid item xs={6} key={i}>
                    <h5 className="text-primary">{w.role}</h5>
                    <p>First Name: {w.info.first_name}</p>
                    <p>Last Name: {w.info.last_name}</p>
                    <p>Email: {w.info.email}</p>
                    <p>Phone: {w.info.phone}</p>
                  </Grid>
                ))}
              </Grid>
            </PortletBody>
          </Portlet>
        )}
        <br />
        <Portlet>
          <PortletHeader title="Admin Information" />
          <PortletBody>
            <Grid container spacing={2}>
              {MI.from === "opportunities" && (
                <>
                  <Grid item xs={12}>
                    <label className="form-label" htmlFor="program">
                      Program
                    </label>
                    {props.module.type === "create" && (
                      <Select
                        className="kt-width-full"
                        name="program"
                        options={state.productWithPrograms}
                        value={{
                          label:
                            state.productWithPrograms.length > 0
                              ? state.productWithPrograms[0].label
                              : "",
                          value: "",
                        }}
                        placeholder="Please select program"
                      />
                    )}
                    {props.module.type === undefined && allowToEdit ? (
                      <Select
                        className="kt-width-full"
                        name="program"
                        options={state.productWithPrograms}
                        onChange={(v) => onSelectHandler(v, "program")}
                        value={info.program}
                        placeholder="Please select program"
                      />
                    ) : (
                      <h6 className="text-primary">
                        {info.program && info.program.label}
                      </h6>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <label className="form-label" htmlFor="workforces">
                  Workforces
                </label>
                {allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="workforces"
                    options={state.workforces}
                    onChange={(v) => onSelectHandler(v, "workforces")}
                    value={info.workforces}
                    isMulti
                    placeholder="Please select Workforces"
                  />
                ) : (
                  <h6 className="text-primary">
                    {info.workforces &&
                      info.workforces.map((v, i) =>
                        i === 0 ? v.label : ", " + v.label
                      )}
                  </h6>
                )}
              </Grid>

              <Grid item xs={12}>
                <label className="form-label" htmlFor="contacts">
                  Contacts
                </label>
                {allowToEdit ? (
                  <Select
                    className="kt-width-full"
                    name="contacts"
                    options={state.contacts}
                    onChange={(v) => onSelectHandler(v, "contacts")}
                    value={info.contacts}
                    isMulti
                    placeholder="Please select Contacts"
                  />
                ) : (
                  <h6 className="text-primary">
                    {info.contacts &&
                      info.contacts.map((v, i) =>
                        i === 0 ? v.label : ", " + v.label
                      )}
                  </h6>
                )}
              </Grid>

              {MI.from === "opportunities" && (
                <Grid item xs={12}>
                  <label className="form-label" htmlFor="groups">
                    Groups
                  </label>
                  {allowToEdit ? (
                    <Select
                      className="kt-width-full"
                      name="groups"
                      options={state.groups}
                      onChange={(v) => onSelectHandler(v, "groups")}
                      value={info.groups}
                      isMulti
                      placeholder="Please select groups"
                    />
                  ) : (
                    <h6 className="text-primary">
                      {info.groups &&
                        info.groups.map((v, i) =>
                          i === 0 ? v.label : ", " + v.label
                        )}
                    </h6>
                  )}
                </Grid>
              )}

              {MI.from === "opportunities" && (
                <Grid item xs={12}>
                  <label className="form-label" htmlFor="status">
                    Status
                  </label>
                  {props.module.type === "create" && (
                    <Select
                      className="kt-width-full"
                      name="program"
                      options={state.status}
                      value={{
                        label:
                          state.status.length > 0 ? state.status[0].label : "",
                        value: "",
                      }}
                      placeholder="Please select program"
                    />
                  )}
                  {props.module.type === undefined && allowToEdit ? (
                    <Select
                      className="kt-width-full"
                      name="status"
                      options={state.status}
                      onChange={(v) => onSelectHandler(v, "status")}
                      value={info.status}
                      placeholder="Please select Status"
                    />
                  ) : (
                    <h6 className="text-primary">
                      {info.status &&
                        info.status.map((v, i) =>
                          i === 0 ? v.label : ", " + v.label
                        )}
                    </h6>
                  )}
                </Grid>
              )}
            </Grid>
          </PortletBody>
        </Portlet>
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Required Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              <table style={{ width: "100%" }}>
                <thead>
                  <th></th>
                  <th>Document</th>
                  <th>Status</th>
                </thead>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e, 1)}
                    ></input>
                  </td>
                  <td>Driving License</td>
                  <td>
                    <Badge variant="success">Signed</Badge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e, 2)}
                    ></input>
                  </td>
                  <td>Passport</td>
                  <td>
                    <Badge variant="danger">Unsigned</Badge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handleCheckbox(e, 3)}
                    ></input>
                  </td>
                  <td>Aadhaar</td>
                  <td>
                    <Badge variant="success">Signed</Badge>
                  </td>
                </tr>
              </table>
            }
          </Modal.Body>
          <Modal.Footer>
            {selectedDocuments.length > 0 && <Button>Send Documents</Button>}
          </Modal.Footer>
        </Modal>
      </Grid>
      {MI.from === "opportunities" &&
        props.user.type === "Workforces" &&
        openPopup && (
          <AssignedProspect
            opportunityId={MI.opportunity._id}
            prospects={state.prospects}
            openPopup={setOpenPopup}
            selected={MI.assigned_prospects}
            setMsg={setActionMsg}
            onHandleSave={handleLoadUpdate}
          />
        )}
      {msg.show && (
        <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
      )}
    </Grid>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Admin);

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";

import { Portlet, PortletBody } from "../partials/content/Portlet";
import "./mailbox.css";
import { backendApiUrl } from "../utils";
import Message from "../utils/Message";

const Counter = (props) => {
  const [title, setTitle] = useState("");

  const [msg, setMsg] = useState({});

  const createEmailList = async (type) => {
    await axios
      .post(
        backendApiUrl("campaign/create_status_emaillist"),
        { title, campaignId: props.state.campaign_id, type },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        document.body.click();
        setMsg({
          status: 200,
          msg: "Email List has been created successfully.",
          show: true,
        });
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Something went wrong, unable to create template.",
          show: true,
        });
      });
  };

  const status = [
    { type: "send", label: "Sent" },
    { type: "delivery", label: "Delivered" },
    { type: "open", label: "Opened" },
    { type: "click", label: "Clicked" },
    { type: "bounce", label: "Bounced" },
    { type: "unsubscribed", label: "Unsubscribed" },
  ];

  return (
    <Grid container spacing={2}>
      {status.map((s, i) => (
        <Grid item xs={2} align="center" key={i}>
          <Portlet>
            <PortletBody className="p-3">
              {s.label}
              <h3
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => props.onClickHandler(s.type)}
              >
                {props.state.mailCnts[s.type]
                  ? props.state.mailCnts[s.type]
                  : 0}
              </h3>

              {msg.show && (
                <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
              )}
              {props.module === "Campaign" && (
                <>
                  {props.state.mailCnts && props.state.mailCnts[s.type] > 0 ? (
                    <OverlayTrigger
                      placement="top"
                      trigger="click"
                      rootClose
                      overlay={
                        <Tooltip className="tooltip-lg">
                          Would you like to create an email list for these{" "}
                          {props.state.mailCnts[s.type]} {s.label} Messages?
                          <div className="form-group">
                            <label className="control-label">
                              Email list Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                          <span align="center">
                            <Badge
                              variant="success"
                              className="pointer mr-2"
                              onClick={() => createEmailList(s.type)}
                            >
                              Create
                            </Badge>
                            <Badge
                              variant="danger"
                              className="pointer"
                              onClick={() => document.body.click()}
                            >
                              Close
                            </Badge>
                          </span>
                        </Tooltip>
                      }
                    >
                      <span
                        className="fa text-primary"
                        onClick={(e) => setTitle("")}
                      >
                        Create Email List
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <span className="fa text-primary">No Emails</span>
                  )}
                </>
              )}
            </PortletBody>
          </Portlet>
        </Grid>
      ))}
    </Grid>
  );
};

export default Counter;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { OverlayTrigger, Badge, Popover } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { backendApiUrl } from "../utils";

const ProspectStatus = ({
  status,
  opportunityId,
  prospectId,
  eachProspectStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  useEffect(() => {
    // Set the initial selected status when eachProspectStatus changes
    const prospect = eachProspectStatus.find(
      (prospect) => prospect.prospectId === prospectId
    );
    if (prospect) {
      setSelectedStatus(prospect.statusLabel || "New");
    }
  }, [eachProspectStatus, prospectId]);

  const onChangeHandler = async (e) => {
    setSelectedStatus(e.target.value);
    const selectedStatusLabel = e.target.value;
    const selectedStatusValue = status.find(
      (st) => st.label === selectedStatusLabel
    )?.value;
    await axios
      .post(
        backendApiUrl("opportunities/prospect_status_update"),
        { opportunityId, prospectId, status: selectedStatusValue },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {});
  };

  return (
    <OverlayTrigger
      placement="top"
      trigger="click"
      rootClose
      overlay={
        <Popover>
          <select
            className="form-control"
            onChange={onChangeHandler}
            value={selectedStatus}
          >
            <option>select status</option>
            {status.map((pStatus, i) => (
              <option key={i}>{pStatus.label}</option>
            ))}
          </select>
        </Popover>
      }
    >
      <Badge variant="success" className="mr-2">
        {selectedStatus} <FaChevronDown />
      </Badge>
    </OverlayTrigger>
  );
};
const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(ProspectStatus);

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import Select from "react-select";
import { connect } from "react-redux";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../partials/content/Portlet";
import { backendApiUrl } from "../../utils";
import Message from "../../utils/Message";

const Notes = (props) => {
  const [load, setLoad] = useState(true);

  const [msg, setMsg] = useState({ show: false });

  const [notes, setNotes] = useState([]);

  const [users, setUsers] = useState([]);

  const [note, setNote] = useState({});

  const userOnSelectHandler = (us) => {
    let n = { ...note };
    n.users = us;
    setNote(n);
  };

  const openEditNote = (data) => {
    let sharedUsers = [];

    if (data.users) {
      data.users.workforces &&
        data.users.workforces.forEach((w) => {
          w.label = "";
          if (w.info) {
            if (!!w.info.first_name)
              w.label += !!w.label
                ? " | " + w.info.first_name
                : w.info.first_name;
            if (!!w.info.last_name)
              w.label += !!w.label ? " " + w.info.last_name : w.info.last_name;
            if (!!w.info.email)
              w.label += !!w.label ? " | " + w.info.email : w.info.email;
            if (!!w.info.phone)
              w.label += !!w.label ? " | " + w.info.phone : w.info.phone;
          }
          w.value = w._id;
          w.type = "workforces";
          sharedUsers = [...sharedUsers, w];
        });

      data.users.contacts &&
        data.users.contacts.forEach((w) => {
          w.label = "";
          if (w.info) {
            if (!!w.info.first_name)
              w.label += !!w.label
                ? " | " + w.info.first_name
                : w.info.first_name;
            if (!!w.info.last_name)
              w.label += !!w.label ? " " + w.info.last_name : w.info.last_name;
            if (!!w.info.email)
              w.label += !!w.label ? " | " + w.info.email : w.info.email;
            if (!!w.info.phone)
              w.label += !!w.label ? " | " + w.info.phone : w.info.phone;
          }
          w.value = w._id;
          w.type = "contacts";
          sharedUsers = [...sharedUsers, w];
        });

      data.users.prospects &&
        data.users.prospects.forEach((w) => {
          w.label = "";
          if (w.info) {
            if (!!w.info.first_name)
              w.label += !!w.label
                ? " | " + w.info.first_name
                : w.info.first_name;
            if (!!w.info.last_name)
              w.label += !!w.label ? " " + w.info.last_name : w.info.last_name;
            if (!!w.info.email)
              w.label += !!w.label ? " | " + w.info.email : w.info.email;
            if (!!w.info.phone)
              w.label += !!w.label ? " | " + w.info.phone : w.info.phone;
          }
          w.value = w._id;
          w.type = "prospects";
          sharedUsers = [...sharedUsers, w];
        });
    }

    let n = { ...data };
    n.module = props.module;
    n.users = sharedUsers;
    n.owner = props.owner;
    n.load = true;
    setNote(n);
  };

  const onChangeHandler = (event) => {
    let n = { ...note };
    n[event.target.id] = event.target.value;
    setNote(n);
  };

  const saveOnClickHandler = () => {
    let data = { ...note };
    let shared = {};
    note.users &&
      note.users.forEach((u) => {
        if (shared[u.type]) {
          shared[u.type] = [...shared[u.type], u._id];
        } else {
          shared[u.type] = [u._id];
        }
      });
    data.users = shared;
    data.MI = props.MI;

    axios
      .post(backendApiUrl("notes"), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setNote({
          module: props.module,
          owner: props.owner,
        });
        setMsg({
          status: 200,
          msg: "Notes created successfully.",
          show: true,
        });
        setLoad(true);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Unable to create note. please try again!",
          show: true,
        });
      });
  };

  const editOnClickHandler = (id) => {
    let data = { ...note };
    let shared = {};
    note.users.forEach((u) => {
      if (shared[u.type]) {
        shared[u.type] = [...shared[u.type], u._id];
      } else {
        shared[u.type] = [u._id];
      }
    });
    data.users = shared;    
    data.MI = props.MI;
    axios
      .patch(backendApiUrl("notes/" + note._id), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setNote({
          module: props.module,
          owner: props.owner,
        });
        setMsg({
          status: 200,
          msg: "Notes updated successfully.",
          show: true,
        });
        setLoad(true);
      })
      .catch((err) => {
        setMsg({
          status: 500,
          msg: "Unable to create note. please try again!",
          show: true,
        });
      });
  };

  useEffect(() => {
    if (load) {
      axios
        .post(
          backendApiUrl("notes/list"),
          {
            module: props.module,
            owner: props.owner,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let users = [];
          let u = [];
          res.data.workforces.forEach((w) => {
            w.label = "";
            if (w.info) {
              if (!!w.info.first_name)
                w.label += !!w.label
                  ? " | " + w.info.first_name
                  : w.info.first_name;
              if (!!w.info.last_name)
                w.label += !!w.label
                  ? " " + w.info.last_name
                  : w.info.last_name;
              if (!!w.info.email)
                w.label += !!w.label ? " | " + w.info.email : w.info.email;
              if (!!w.info.phone)
                w.label += !!w.label ? " | " + w.info.phone : w.info.phone;
            }
            w.value = w._id;
            w.type = "workforces";
            u = [...u, w];
          });
          users = [...users, { label: "Workforces", options: u }];

          let uc = [];
          res.data.contacts.forEach((c) => {
            c.label = "";
            c.value = c._id;
            if (c.info) {
              if (!!c.info.first_name)
                c.label += !!c.label
                  ? " | " + c.info.first_name
                  : c.info.first_name;
              if (!!c.info.last_name)
                c.label += !!c.label
                  ? " " + c.info.last_name
                  : c.info.last_name;
              if (!!c.info.email)
                c.label += !!c.label ? " | " + c.info.email : c.info.email;
              if (!!c.info.phone)
                c.label += !!c.label ? " | " + c.info.phone : c.info.phone;
            }
            c.type = "contacts";
            uc = [...uc, c];
          });
          users = [...users, { label: "Contacts", options: uc }];

          let up = [];
          res.data.prospects.forEach((p) => {
            p.label = "";
            p.value = p._id;
            if (p.info) {
              if (!!p.info.first_name)
                p.label += !!p.label
                  ? " | " + p.info.first_name
                  : p.info.first_name;
              if (!!p.info.last_name)
                p.label += !!p.label
                  ? " " + p.info.last_name
                  : p.info.last_name;
              if (!!p.info.email)
                p.label += !!p.label ? " | " + p.info.email : p.info.email;
              if (!!p.info.phone)
                p.label += !!p.label ? " | " + p.info.phone : p.info.phone;
            }
            p.type = "prospects";
            up = [...up, p];
          });
          users = [...users, { label: "Prospects", options: up }];
          setUsers(users);

          setNotes(res.data.notes);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    }
  }, [load, props.module, props.owner]);

  let permissions = {};
  if (props.user.role.admin || props.module.MDID === "") {
    permissions = { add: true, update: true, delete: true };
  } else if (props.permissions && props.permissions.notes) {
    permissions = props.permissions.notes;
  }
  let allowToEdit = false;
  if (permissions.add || permissions.update) allowToEdit = true;

  return (
    <Portlet>
      <PortletHeader
        title="Notes"
        toolbar={
          <PortletHeaderToolbar>
            {permissions.add && (
              <button
                className="btn btn-primary btn-sm"
                onClick={() =>
                  setNote({
                    module: props.module,
                    owner: props.owner,
                    load: true,
                  })
                }
              >
                Create Notes
              </button>
            )}
          </PortletHeaderToolbar>
        }
      />
      <PortletBody>
        <Grid container spacing={2}>
          {msg.show && (
            <Message status={msg.status} setMsg={setMsg} msg={msg.msg} />
          )}

          {note.load && (
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div className="form-group">
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="content">
                      Notes 
                    </label>
                  </div>
                  {allowToEdit ? (
                    <textarea
                      name="content"
                      id="content"
                      onChange={onChangeHandler}
                      value={note.content ? note.content : ""}
                      className="form-control"
                    />
                  ) : (
                    <h6 className="text-primary">{note.content}</h6>
                  )}
                </div>
              </Grid>
              <Grid item md={2}>
                <div className="form-group">
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="type">
                      Type
                    </label>
                  </div>
                  {allowToEdit ? (
                    <select
                      name="type"
                      id="type"
                      onChange={onChangeHandler}
                      value={note.type ? note.type : ""}
                      className="form-control"
                    >
                      <option>Select type</option>
                      <option>Public</option>
                      <option>Private</option>
                    </select>
                  ) : (
                    <h6 className="text-primary">{note.type}</h6>
                  )}
                </div>
              </Grid>
              {note.type === "Private" && (
                <Grid item md={3}>
                  <div className="form-group">
                    <div className="col-md-12">
                      <label className="form-label" htmlFor="users">
                        Users
                      </label>
                    </div>
                    {allowToEdit ? (
                      <Select
                        className="kt-width-full"
                        options={users}
                        value={note.users}
                        onChange={userOnSelectHandler}
                        isMulti
                        placeholder="Please select"
                      />
                    ) : (
                      <h6 className="text-primary">
                        {note.users.forEach((u) => (
                          <span>
                            {u.label}
                            <br />
                          </span>
                        ))}
                      </h6>
                    )}
                  </div>
                </Grid>
              )}
              <Grid item md={1}>
                {note._id ? (
                  <button
                    className="btn btn-success btn-sm"
                    style={{ marginTop: "25px" }}
                    onClick={editOnClickHandler}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-sm"
                    style={{ marginTop: "25px" }}
                    onClick={saveOnClickHandler}
                  >
                    Save
                  </button>
                )}
              </Grid>
            </Grid>
          )}

          {load ? (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <div style={{ textAlign: "center" }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              </Grid>
            </Grid>
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th nowrap="true" width="60%">
                    Notes
                  </th>
                  <th nowrap="true">Type</th>
                  <th nowrap="true">Created By</th>
                  <th nowrap="true">Updated By</th>
                  <th nowrap="true">Actions</th>
                </tr>
              </thead>
              <tbody>
                {notes.length > 0 ? (
                  notes.map((n, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{n.content}</td>
                      <td>{n.type}</td>
                      <td>
                        {n.created_by.user}
                        <br />
                        <span style={{ textAlign: "right" }}>
                          At: {n.createdAt}
                        </span>
                      </td>
                      <td>
                        {n.updated_by.user}
                        <br />
                        <span style={{ textAlign: "right" }}>
                          At: {n.updatedAt}
                        </span>
                      </td>
                      <td>
                        <i
                          className="fa fa-edit fa-lg text-primary mr-2"
                          title="Edit the note."
                          onClick={(e) => openEditNote(n)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h5 className="text-primary text-center">
                        No notes available, create one!
                      </h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Grid>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Notes);

import React from "react";
import FormGroup from "./utils/FormGroup";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const DateTimePicker = (props) => {
  let val = "";
  if (props.element.value && !!props.element.value)
    val = new Date(props.element.value);

  const onChangeHandler = (obj, id, elm) => {
    if (elm != null) props.events.change(obj, id, elm);
  };

  return (
    <FormGroup element={props.element} module={props.module}>
      {props.module.permissions.update ? (
        <DatePicker
          dateFormat="MM-dd-yyyy"
          id={props.element.id}
          selected={val}
          className={"form-control " + props.element.class}
          onChange={(d) => onChangeHandler({}, props.element.id, d)}
          placeholderText="MM-DD-YYYY"
          customInput={<InputMask mask="99-99-9999" type="text" />}
        />
      ) : (
        <h6 className="text-primary">{props.element.value}</h6>
      )}
    </FormGroup>
  );
};

export default DateTimePicker;

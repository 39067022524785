import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { backendApiUrl } from "../../utils";

const StatusUpdate = (props) => {
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(
    props.data.status
      ? { label: props.data.status, value: props.data.status }
      : null
  );

  const statusSelectHandler = (tempStatus) => {
    setStatus(tempStatus);
    if (tempStatus === null) tempStatus = {};

    axios
      .patch(
        backendApiUrl("tasks/" + props.data._id),
        { status: tempStatus !== null ? tempStatus.value : "" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        props.setMsg({
          status: 200,
          msg: "Status has been updated successfully..",
          show: true,
        });
      })
      .catch((err) => {
        props.setMsg({
          status: 500,
          msg: "Problem while updating status, please try again.",
          show: true,
        });
      });
  };

  return (
    <div>
      <i
        className="fa fa-edit fa-lg text-primary mr-2"
        onClick={() => setEdit(edit ? false : true)}
        title="Click to update status"
      />
      {edit ? (
        <Select
          className="kt-width-full"
          name="status"
          options={props.status}
          onChange={statusSelectHandler}
          value={status}
          isDisabled={props.data.readonly ? true : false}
          placeholder="Please select"
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
        />
      ) : (
        <span>
          {props.data.status === "Not Started" && (
            <Badge variant="danger">{props.data.status}</Badge>
          )}
          {props.data.status === "In Progress" && (
            <Badge variant="primary" className="badge-inprogress">
              {props.data.status}
            </Badge>
          )}
          {props.data.status === "Awaiting Feedback" && (
            <Badge variant="warning">{props.data.status}</Badge>
          )}
          {props.data.status === "On Hold" && (
            <Badge variant="info">{props.data.status}</Badge>
          )}
          {props.data.status === "Completed" && (
            <Badge variant="success">{props.data.status}</Badge>
          )}
        </span>
      )}
    </div>
  );
};

export default StatusUpdate;

export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        icon: "fa fa-tachometer-alt",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Companies",
        icon: "fa fa-building",
        root: true,
        page: "companies",
        alignment: "left",
      },
      {
        title: "Users",
        icon: "fa fa-user",
        root: true,
        page: "users",
        alignment: "left",
      },
      {
        title: "Contacts",
        icon: "fa fa-address-book",
        root: true,
        page: "contacts",
        alignment: "left",
      },
      {
        title: "Roles",
        icon: "fas fa-key",
        root: true,
        page: "roles",
        alignment: "left",
      },
      {
        title: "Groups",
        icon: "fa fa-users",
        root: true,
        page: "groups",
        alignment: "left",
      },
      {
        title: "Groups",
        icon: "fa fa-users",
        root: true,
        page: "groups",
        alignment: "left",
      },
      {
        title: "Builders",
        icon: "kt-menu__link-icon flaticon2-browser",
        root: true,
        alignment: "left",
        submenu: [
          {
            title: "Section Builder",
            page: "sections",
          },
          {
            title: "App Builder",
            page: "pages",
          },
          {
            title: "Webform Builder",
            page: "webforms",
          },
        ],
      },
      {
        title: "Apps",
        icon: "fa fa-users",
        root: true,
        alignment: "left",
        submenu: [
          {
            title: "Docs",
          },
          {
            title: "Mailler",
          },
          {
            title: "Messenger",
          },
          {
            title: "WorkFlow",
          },
        ],
      },
      {
        title: "settings",
        icon: "fa fa-cog",
        root: true,
        alignment: "left",
      },
    ],
  },
  aside: {
    items: [
      { section: "Controls" },
      {
        title: "Form Structure",
        icon: "flaticon2-browser",
        root: true,
        submenu: [
          {
            title: "Section",
            icon: "flaticon-browser",
            isDrag: true,
            type: "section",
          },
          {
            title: "Grid",
            icon: "flaticon-folder-4",
            isDrag: true,
            type: "grid",
          },
        ],
      },
      {
        title: "Text Controls",
        root: true,
        icon: "flaticon2-edit",
        page: "builder",
        submenu: [
          {
            title: "Text Field",
            icon: "flaticon-edit",
            isDrag: true,
            type: "text",
          },
          {
            title: "Plain Text Area",
            icon: "flaticon-list",
            isDrag: true,
            type: "textarea",
          },
        ],
      },
      {
        title: "Date & Time",
        root: true,
        icon: "flaticon2-calendar-1",
        page: "builder",
        submenu: [
          {
            title: "Date",
            icon: "flaticon-calendar-2",
            isDrag: true,
            type: "date",
          },
          {
            title: "Time",
            icon: "flaticon-clock-1",
            isDrag: true,
            type: "time",
          },
          {
            title: "Date & Time",
            icon: "flaticon-clock-2",
            isDrag: true,
            type: "datetime",
          },
        ],
      },
      {
        title: "Selection Controls",
        root: true,
        icon: "flaticon2-folder",
        page: "builder",
        submenu: [
          {
            title: "Select",
            icon: "flaticon-list-2",
            isDrag: true,
            type: "select",
          },
          {
            title: "Multi Select",
            icon: "flaticon-list-2",
            isDrag: true,
            type: "multiselect",
          },
          {
            title: "Radio Buttons",
            icon: "flaticon-signs-1",
            isDrag: true,
            type: "radio",
          },
          {
            title: "Checkboxes",
            icon: "flaticon-signs-1",
            isDrag: true,
            type: "checkbox",
          },
        ],
      },
      {
        title: "Typed Controls",
        root: true,
        icon: "flaticon2-edit",
        page: "builder",
        submenu: [
          {
            title: "Number",
            icon: "flaticon-calendar-1",
            isDrag: true,
            type: "number",
          },
          {
            title: "Password",
            icon: "flaticon-calendar-1",
            isDrag: true,
            type: "password",
          },
          {
            title: "Email Address",
            icon: "flaticon-multimedia",
            isDrag: true,
            type: "email",
          },
          {
            title: "Phone Number",
            icon: "flaticon-support",
            isDrag: true,
            type: "phone",
          },
          {
            title: "Cell",
            icon: "flaticon-support",
            isDrag: true,
            type: "cell",
          },
          {
            title: "Social Security Number",
            icon: "flaticon-security",
            isDrag: true,
            type: "ssn",
          },
          {
            title: "Amount",
            icon: "flaticon-piggy-bank",
            isDrag: true,
            type: "amount",
          },
          {
            title: "Zip",
            icon: "fa fa-map-pin",
            isDrag: true,
            type: "zip",
          },
          {
            title: "US states",
            icon: "fa fa-map-pin",
            isDrag: true,
            type: "zip",
          },
          {
            title: "Website",
            icon: "flaticon-customer",
            isDrag: true,
            type: "website",
          },
        ],
      },
      {
        title: "Attachments",
        root: true,
        icon: "flaticon2-image-file",
        page: "builder",
        submenu: [
          {
            title: "File Attachment",
            icon: "flaticon-attachment",
            isDrag: true,
            type: "file",
          },
          {
            title: "Image Attachment",
            icon: "flaticon2-schedule",
            isDrag: true,
            type: "image",
          },
          {
            title: "Handwritten Signature",
            icon: "flaticon-edit-1",
            isDrag: true,
            type: "signature",
          },
          {
            title: "Dropzone",
            icon: "flaticon-upload-1",
            isDrag: true,
            type: "dropzone",
          },
        ],
      },
      {
        title: "Utility Control",
        root: true,
        icon: "flaticon2-settings",
        page: "builder",
        submenu: [
          {
            title: "Header Text",
            icon: "flaticon-analytics",
            isDrag: true,
            type: "headertext",
          },
          {
            title: "Separator",
            icon: "flaticon-analytics",
            isDrag: true,
            type: "separator",
          },
          {
            title: "Paragraph",
            icon: "flaticon-analytics",
            isDrag: true,
            type: "paragraph",
          },
        ],
      },
      {
        title: "Buttons",
        root: true,
        icon: "flaticon2-cube-1",
        page: "builder",
        submenu: [
          {
            title: "Button",
            icon: "flaticon-add-circular-button",
            isDrag: true,
            type: "button",
          },
        ],
      },
    ],
  },
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Message from "../utils/Message";
import { Redirect, useParams } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MdAddLink } from "react-icons/md";

import FormElements from "../form_elements";
import {
  controlSettings,
  removeElementFromForm,
  bindInputDataToState,
  checkValidation,
  checkCloneableSectionTotal,
  addLabelForSelect,
  processCalculation,
  processControlSettings,
} from "../utils/FormHandling";
import { backendApiUrl, getAllowedTabs } from "../utils";
import Docs from "../docs";
import ActionButtons from "../utils/ActionButtons";
import Emails from "../campaigns/Emails";
import Notes from "../services/notes";
import Tasks from "../services/tasks";
import History from "../services/history";
import { metronic } from "../../_metronic";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../partials/content/Portlet";
import RequiredFields from "../utils/RequiredFields";
import Loader from "./Loader";

const Edit = (props) => {
  let mid = useParams().opportunity_id;
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tab = params.get("tab");
  const [upload, setUpload] = useState(0);

  const [moduleInfo, setModuleInfo] = useState({
    msg: { show: false },
    load: true,
    data: {},
    files: [],
    form: {},
    from: "opportunities",
    admin: { productChanged: false },
    MDID: mid,
    permissions: {},
    requiredFields: [],
    uploadInfo: {},
    isTabForm: false,
    isEmailIsAvailable: false,
    allowed_tabs: [],
    uploadFieldsCnt: 0,
    allowToView: true,
    assigned_prospects: [],
  });

  const productSelectHandler = (product) => {
    let MI = { ...moduleInfo };
    let programs = [];
    if (product === null) product = [];
    programs = [
      ...programs,
      {
        label: product.title,
        options: addLabelForSelect(
          MI.admin.allPrograms,
          "_id",
          ["title"],
          false
        ),
      },
    ];

    MI.admin.selProduct = product;
    MI.admin.programs = programs;
    MI.admin.productChanged = true;
    setModuleInfo(MI);
  };

  const programSelectHandler = (selPrograms) => {
    let MI = { ...moduleInfo };
    if (selPrograms === null) selPrograms = [];
    MI.admin.selProgram = selPrograms;
    MI.admin.productChanged = true;
    setModuleInfo(MI);
  };

  const workforceSelectHandler = (workforces) => {
    let MI = { ...moduleInfo };
    if (workforces === null) workforces = [];
    MI.admin.selWorkforces = workforces;
    setModuleInfo(MI);
  };

  const contactSelectHandler = (contacts) => {
    let MI = { ...moduleInfo };
    if (contacts === null) contacts = [];
    MI.admin.selContacts = contacts;
    setModuleInfo(MI);
  };

  const statusSelectHandler = (status) => {
    let MI = { ...moduleInfo };
    if (status === null) status = [];
    MI.admin.selStatus = status;
    setModuleInfo(MI);
  };

  const sourceSelectHandler = (source) => {
    let MI = { ...moduleInfo };
    if (source === null) source = [];
    MI.admin.selSource = source;
    setModuleInfo(MI);
  };

  const groupSelectHandler = (group) => {
    let MI = { ...moduleInfo };
    if (group === null) group = {};
    MI.admin.selGroup = group;
    MI.admin.workforces = addLabelForSelect(
      group.workforces,
      "_id",
      ["first_name", "last_name", "email", "phone"],
      true
    );
    MI.admin.products = addLabelForSelect(
      group.products,
      "_id",
      ["title"],
      true
    );
    MI.admin.allPrograms = addLabelForSelect(
      group.programs,
      "_id",
      ["title"],
      true
    );
    setModuleInfo(MI);
  };

  // On Field Change Handler
  const onFieldChangeHandler = (event, elmId, value) => {
    setModuleInfo(bindInputDataToState(event, elmId, moduleInfo, value));
  };

  // Submit data to API
  const onSaveButtonClickHandler = async (mi) => {
    let MI = mi && mi.files ? mi : { ...moduleInfo };

    if (MI.permissions && !MI.permissions.update) return true;

    // Validation check

    if (mi !== "Force Update") {
      let requiredFields = checkValidation("LS_mandatory", moduleInfo);
      if (requiredFields.length > 0) {
        MI.requiredFields = requiredFields;
        setModuleInfo(MI);

        var myDiv = document.getElementById("kt_content");
        myDiv.scrollTop = 0;
        return true;
      }
    }

    let data = {};
    let admin_info = {};
    //if (MI.admin.product && MI.admin.product._id)
    //  admin_info.product = MI.admin.product._id;
    if (MI.admin.program) {
      let obj = MI.admin.program.value.split("---");
      admin_info.product = obj[0];
      admin_info.program = obj[1];
    }

    let workforces = [];
    MI.admin.workforces &&
      MI.admin.workforces.forEach((workforce) => {
        workforces = [...workforces, workforce.value];
      });

    let contacts = [];
    MI.admin.contacts &&
      MI.admin.contacts.forEach((contact) => {
        contacts = [...contacts, contact.value];
      });

    let status = [];
    MI.admin.status &&
      MI.admin.status.forEach((s) => {
        status = [...status, s._id];
      });

    let source = [];
    MI.admin.source &&
      MI.admin.source.forEach((s) => {
        source = [...source, s._id];
      });

    let groups = [];
    MI.admin.groups &&
      MI.admin.groups.forEach((s) => {
        groups = [...groups, s._id];
      });

    admin_info.groups = groups;
    admin_info.status = status;
    admin_info.source = source;
    admin_info.workforces = workforces;
    admin_info.prospects = MI.assigned_prospects?.map((s) => s.value) || [];
    admin_info.contacts = contacts;
    data.adminInfo = { ...admin_info };
    data.formData = { ...MI.data };

    await axios
      .patch(backendApiUrl("opportunities/" + MI.MDID), data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        MI.data = {};
        if (MI.admin.productChanged) {
          MI.admin.productChanged = false;
          MI.load = true;
        }
        MI.requiredFields = [];
        if (MI.files !== undefined && Object.keys(MI.files).length > 0) {
          setUpload(30);
          let data = new FormData();
          let fmodule = "";
          let fowner = "";

          Object.keys(MI.files).forEach(async (file) => {
            let f = MI.files[file];
            fmodule = f.module;
            fowner = f.owner;
            data.append("status--" + f.docType, f.status); // Document status

            if (Array.isArray(f.files) && f.files.length > 0) {
              f.files.forEach((file) => {
                data.append(
                  "file",
                  file,
                  f.module +
                    "&$&$&$&" +
                    f.owner +
                    "&$&$&$&" +
                    f.docType +
                    "&$&$&$&" +
                    file.name +
                    "&$&$&$&" +
                    file.size
                );
              });
            }
          });

          data.append("owner", fowner); // Document owner id
          data.append("module", fmodule); // Document module id
          data.append("form", MI.form._id); // Form id

          await axios
            .post(backendApiUrl("docs/upload"), data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((ul) => {
              setUpload(100);

              MI.msg = {
                status: 200,
                msg:
                  res.data.needDataReview === true
                    ? "All Data Points against have been posted successfully, If there are sections where a review is required. Data will be approved after reviewer has accepted those changes. Contact Admin for more information."
                    : "Opportunity updated successfully.",
                show: true,
              };
              MI.needDataReview = res.data.needDataReview ? true : false;
              MI.uploadInfo = {};
              MI.files = {};
              setModuleInfo(MI);

              setTimeout(() => {
                MI.load = true;
                setUpload(0);
                setModuleInfo(MI);
              }, 2000);
            });
        } else {
          window.scrollTo(0, 0);
          MI.msg = {
            status: 200,
            msg:
              res.data.needDataReview === true
                ? "All Data Points against have been posted successfully, If there are sections where a review is required. Data will be approved after reviewer has accepted those changes. Contact Admin for more information."
                : "Opportunity updated successfully.",
            show: true,
          };
          MI.needDataReview = res.data.needDataReview ? true : false;
          setModuleInfo(MI);
        }
      })
      .catch((error) => {
        MI.load = true;
        MI.msg = {
          status: 500,
          msg: "Something went wrong, could not update opportunity.",
          show: true,
        };
        setModuleInfo(MI);
      });
  };

  // Load opportunity Form Data
  useEffect(() => {
    if (moduleInfo.load) {
      let MI = { ...moduleInfo };
      axios
        .get(backendApiUrl("opportunities/" + MI.MDID))
        .then((res) => {
          if (res.data.message === "Invalid access.") {
            MI.allowToView = false;
            setModuleInfo(MI);
          } else {
            let rs = { ...res.data };

            dispatch(
              metronic.builder.actions.setModuleBuilder({
                prospectId: rs.opportunity.prospect
                  ? rs.opportunity.prospect._id
                  : "",
              })
            );
            MI.form = { ...rs.form };

            // string to json
            if (MI.form.content && typeof MI.form.content[0] === "string") {
              MI.form.content = JSON.parse(MI.form.content[0]);
            }

            MI.opportunity = rs.opportunity;
            if (!!tab) MI.form.activeTab = tab;
            MI.admin = { ...rs };

            let combineProspectsValues = {
              ...(rs.opportunity.info ? rs.opportunity.info : {}),
              ...(rs.opportunity.prospect ? rs.opportunity.prospect.info : {}),
            };
            MI.mandatoryMultiselect = [];
            MI.dynamicFieldData = combineProspectsValues;
            MI.form.hideSectionsAndFields = [];
            MI.form.guidelines = {};

            let cals_fields = [];
            const bindConfig = (configs) => {
              let bindedConfig = [];

              configs &&
                configs.forEach((config) => {
                  if (config.calculations) {
                    cals_fields = [...cals_fields, config];
                  }

                  if (config.type === "file") MI.uploadFieldsCnt += 1;

                  if (config.type === "tab" && MI.isTabForm === false)
                    MI.isTabForm = true;

                  // Get mandatory multiselect
                  if (config.type === "multiselect" && config.mandatory) {
                    MI.mandatoryMultiselect = [
                      ...MI.mandatoryMultiselect,
                      config.id,
                    ];
                  }

                  config.value = "";

                  if (
                    combineProspectsValues &&
                    combineProspectsValues[config.id] !== undefined
                  ) {
                    config.value = combineProspectsValues[config.id];
                  }

                  // Check control settings
                  if (config.control_settings) {
                    let obj = controlSettings(config);
                    MI.form.hideSectionsAndFields = [
                      ...MI.form.hideSectionsAndFields,
                      ...obj.hideElements,
                    ];
                    MI.form.guidelines = {
                      ...MI.form.guidelines,
                      ...obj.guidelines,
                    };
                  }

                  if (config.childs && config.childs.length > 0)
                    bindConfig(config.childs);

                  bindedConfig = [...bindedConfig, config];
                });
              return bindedConfig;
            };
            MI.form.content = bindConfig(MI.form.content);

            if (Array.isArray(MI.form.hideSectionsAndFields)) {
              MI.form.content = removeElementFromForm(
                MI.form.content,
                MI.form.hideSectionsAndFields
              );
            }
            MI.permissions = rs.permissions;
            MI.allowed_tabs = getAllowedTabs(MI, props.user);
            MI.uploadedDocs = res.data.docs;
            MI.webformId = res.data.webformId;

            cals_fields.forEach((c) => {
              MI = processCalculation(MI, c);
            });

            // Loan Guide lines
            MI = processControlSettings(MI);

            MI.load = false;
            setModuleInfo(MI);
          }
        })
        .catch((err) => {
          console.log(err);
          MI.msg = {
            status: 500,
            msg: "Something went wrong, unable to load opportunity.",
            show: true,
          };
          MI.load = false;
          setModuleInfo(MI);
        });
    }
  }, [dispatch, moduleInfo, props.user, tab]);

  const onTabChangeHandler = (newTab) => {
    window.history.replaceState(
      null,
      "New Page Title",
      window.location.pathname + "?tab=" + newTab
    );
    let MI = { ...moduleInfo };
    MI.form.activeTab = newTab;
    setModuleInfo(MI);
  };

  const setFormData = (data) => {
    let MI = { ...moduleInfo };
    MI.data = data;
    MI = checkCloneableSectionTotal(MI);
    setModuleInfo(MI);
  };

  const setActionMsg = (msg) => {
    let MI = { ...moduleInfo };
    MI.msg = msg;
    setModuleInfo(MI);
  };

  const updateForm = (form) => {
    let MI = { ...moduleInfo };
    MI.form = form;
    setModuleInfo(MI);
  };

  if (moduleInfo.allowToView === false) {
    return <Redirect to="/" />;
  }
  /*
  let prefix = "";
  if (
    moduleInfo.form.company &&
    moduleInfo.opportunity &&
    moduleInfo.form.company.prefix &&
    moduleInfo.form.company.prefix[moduleInfo.opportunity.program._id]
  ) {
    prefix = moduleInfo.form.company.prefix[moduleInfo.opportunity.program._id];
  }
  */

  if (moduleInfo.load) {
    return (
      <div align="center">
        <Loader />
      </div>
    );
  } else {
    if (moduleInfo.form.content && moduleInfo.form.content.length) {
      return (
        <div>
          {upload > 0 && (
            <div
              className="row"
              style={{
                position: "sticky",
                top: 0,
                padding: "5px",
                zIndex: 999,
              }}
            >
              <div className="col-md-9"></div>
              <div className="col-md-3">
                Uploading... please wait
                <ProgressBar animated variant="success" now={upload} />
              </div>
            </div>
          )}
          <div className="col-md-12" align="right">
            <Link to={`/forms/${moduleInfo.webformId}?au=${mid}`}>
              <MdAddLink size={30} className="mr-1 mb-2" />
              Submit New Prospect
            </Link>
          </div>

          {/* // prospect Information */}
          {/*}
          <h4
            id="user_info"
            className="ml-3"
            style={{ color: "#061F2B !important" }}
          >
            {moduleInfo.opportunity &&
              moduleInfo.opportunity.info &&
              moduleInfo.opportunity.prospect &&
              !!moduleInfo.opportunity.prospect.info &&
              !!moduleInfo.opportunity.prospect.info.first_name && (
                <p>
                  {prefix +
                    moduleInfo.opportunity.info.auto_increment +
                    " | " +
                    moduleInfo.opportunity.program.title}
                  {!!moduleInfo.opportunity.info.property_address &&
                    " | " + moduleInfo.opportunity.info.property_address}
                  {" | " + moduleInfo.opportunity.prospect.info.first_name}
                  {!!moduleInfo.opportunity.prospect.info.last_name
                    ? " " + moduleInfo.opportunity.prospect.info.last_name
                    : ""}
                  {!!moduleInfo.opportunity.prospect.info.email
                    ? " | " + moduleInfo.opportunity.prospect.info.email
                    : ""}
                  {!!moduleInfo.opportunity.prospect.info.phone
                    ? " | " + moduleInfo.opportunity.prospect.info.phone
                    : ""}
                </p>
              )}
          </h4>
          {*/}

          <input type="hidden" id="prospectId" value={moduleInfo.prospectId} />
          {moduleInfo.msg.show && (
            <Message
              status={moduleInfo.msg.status}
              setMsg={setActionMsg}
              msg={moduleInfo.msg.msg}
              fixed={moduleInfo.needDataReview}
            />
          )}

          {moduleInfo.requiredFields.length > 0 && (
            <RequiredFields
              msg="Please fill all mandatory fields"
              state={{ ...moduleInfo }}
              events={{ setModuleInfo }}
            />
          )}

          {moduleInfo.form.content.map((element, i) => (
            <FormElements
              element={element}
              module={moduleInfo}
              events={{
                submit: onSaveButtonClickHandler,
                change: onFieldChangeHandler,
                tabChange: onTabChangeHandler,
                setData: setFormData,
                setForm: updateForm,
                setProduct: productSelectHandler,
                setProgram: programSelectHandler,
                setWorkforce: workforceSelectHandler,
                setGroup: groupSelectHandler,
                setContact: contactSelectHandler,
                setStatus: statusSelectHandler,
                setSource: sourceSelectHandler,
                setModuleInfo,
              }}
              key={i}
            />
          ))}

          {moduleInfo.allowed_tabs.includes("docs") && !moduleInfo.isTabForm && (
            <div className="mt-3">
              <Docs
                module={moduleInfo}
                events={{
                  setModuleInfo,
                }}
              />
            </div>
          )}

          {moduleInfo.allowed_tabs.includes("emails") && !moduleInfo.isTabForm && (
            <div className="mt-3">
              <Portlet>
                <PortletHeader title="Emails" />
                <PortletBody style={{ padding: "0px" }}>
                  <Emails
                    module={moduleInfo}
                    from={moduleInfo.from}
                    owner={moduleInfo.MDID}
                    email={moduleInfo.dynamicFieldData.email}
                    opp={moduleInfo.opportunity ? moduleInfo.opportunity : {}}
                    MI={moduleInfo}
                    setMsg={setActionMsg}
                  />
                </PortletBody>
              </Portlet>
            </div>
          )}

          {moduleInfo.allowed_tabs.includes("tasks") && !moduleInfo.isTabForm && (
            <div className="mt-3">
              <Tasks
                info={moduleInfo}
                permissions={moduleInfo.permissions}
                module={moduleInfo.form._id}
                owner={moduleInfo.MDID}
              />
            </div>
          )}

          {moduleInfo.allowed_tabs.includes("notes") &&
            moduleInfo.isTabForm === false && (
              <div className="mt-3">
                <Notes
                  module="opportunities"
                  permissions={moduleInfo.permissions}
                  owner={moduleInfo.MDID}
                  from="tabs"
                  MI={moduleInfo}
                />
              </div>
            )}

          {moduleInfo.allowed_tabs.includes("history") &&
            !moduleInfo.isTabForm && (
              <div className="mt-3">
                <History
                  module={moduleInfo}
                  events={{
                    submit: onSaveButtonClickHandler,
                    setModuleInfo,
                  }}
                />
              </div>
            )}

          <ActionButtons
            state={moduleInfo}
            events={{
              onSubmit: onSaveButtonClickHandler,
              setState: setModuleInfo,
              tabChange: onTabChangeHandler,
            }}
          />
        </div>
      );
    } else {
      return <h2>Unable to load form</h2>;
    }
  }
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Edit);
